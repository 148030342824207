<template>
  <div id="HelpingFamilyFriendsPage">
    <!-- Splash curved -->
    <div class="splash-curved">
      <div class="container-fluid">
        <div class="row">
          <div class="container splash-content">
            <div class="row">
              <div class="col-lg-6 order-md-1 col-md-8 order-2 align-self-center">
                <h1 class="main-heading border-top-3 border-bottom-3 py-4">Helping family and friends to spot cancer early</h1>

                <div class="scroll-control mt-lg-5 mt-4">
                  <a class="scroll-link" href="#scroll-point" title="Scroll to the main content"
                    >Scroll down to begin<span class="arrow-down" role="img" aria-hidden="true"></span
                  ></a>
                </div>
              </div>
              <div class="col-lg-6 order-md-2 col-md-4 order-1 col-4 px-0 my-xl-n5 align-self-center">
                <img class="img-fluid" src="../../images/helpingfamilyfriends/daffodil-helpingfamilyfriends.png" alt="Photo of an elderly couple holding hands" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Text wide -->
    <div id="skip-nav" class="container-fluid bg-white">
      <div id="scroll-point" class="row">
        <div class="container content-block">
          <div class="row">
            <div class="col-lg-12">
              <h2 class="h-underline">Introduction</h2>
              <p>
                Now you know a little about spotting cancer early you may want to help your friends and family by sharing what you know. But talking about cancer and encouraging
                people to get changes checked out is not always easy.
              </p>

              <p><strong>In this section, you’ll find out some things that can make the conversation easier</strong>.</p>

              <p>First, let’s take a look at why people may not want to talk about cancer or get signs and symptoms checked out.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Text and word cloud -->
    <div class="container-fluid bg-faded-blue">
      <div class="row">
        <div class="container content-block pb-md-0">
          <div class="row">
            <div class="col-lg-3 align-self-end text-center d-lg-block d-none p-0">
              <img class="img-fluid" src="../../images/helpingfamilyfriends/what-people-say.png" alt="Photo of an elderly man standing with speech bubbles above his head" />
            </div>

            <div class="col-lg-9">
              <h2>Reasons we might not get help</h2>

              <p>These are just some of the reasons why people may be uncomfortable with getting the help they need.</p>

              <p class="text-icon">
                <span class="icon-type icon-pointer bg-secondary text-white"></span><strong>Select each of the reasons/words below to learn what people say</strong>.
              </p>

              <div class="row mb-4">
                <div class="col-lg-12 text-center" data-aos="fade-up" data-aos-duration="2000">
                  <div class="word-cloud">
                    <ul>
                      <li>
                        <b-button variant="link" class="rounded-0 p-0" v-b-modal.anxietyModal> <span style="--tag-multiplier: 1.5">Anxiety</span> </b-button>,
                      </li>
                      <li>
                        <b-button variant="link" class="rounded-0 p-0" v-b-modal.pastExperiencesModal> <span style="--tag-multiplier: 2">Past experiences</span> </b-button>,
                      </li>
                      <li>
                        <b-button variant="link" class="rounded-0 p-0" v-b-modal.denialModal> <span style="--tag-multiplier: 2.5">Denial</span></b-button
                        >,
                      </li>
                      <li>
                        <b-button variant="link" class="rounded-0 p-0" v-b-modal.badExperienceModal
                          ><span style="--tag-multiplier: 1.5">Bad experience of the health system</span></b-button
                        >,
                      </li>
                      <li>
                        <b-button variant="link" class="rounded-0 p-0" v-b-modal.tooBusyModal> <span style="--tag-multiplier: 1.2">Too busy</span></b-button
                        >,
                      </li>
                      <li>
                        <b-button variant="link" class="rounded-0 p-0" v-b-modal.embarrassmentModal> <span style="--tag-multiplier: 2.5">Embarrassment</span></b-button
                        >,
                      </li>
                      <li>
                        <b-button variant="link" class="rounded-0 p-0" v-b-modal.feelingHopelessModal>
                          <span style="--tag-multiplier: 1.5">Feeling hopeless about cancer</span></b-button
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Text and image -->
    <div class="container-fluid bg-white">
      <div class="row">
        <div class="container content-block">
          <div class="row">
            <div class="col-md-8 align-self-center">
              <h2>Tips on talking about cancer</h2>
              <p>
                People have many concerns when thinking about and discussing their health. Their experience and views may be different from yours. Take time to listen and
                understand.
              </p>

              <p>Find a good time but remember people may not be ready to talk when you are. When they are ready, the tips below may help.</p>

              <p class="text-icon"><span class="icon-type icon-pointer bg-secondary text-white"></span><strong>Select each tip to learn more</strong>.</p>
            </div>

            <div class="col-md-4 align-self-center text-center d-md-block d-none">
              <img
                class="img-fluid rounded-circle"
                src="../../images/helpingfamilyfriends/having-a-conversation.jpg"
                alt="Photo of a parent and their child having a conversation"
              />
            </div>
          </div>

          <!-- Vertical tabs -->
          <div class="row mt-4">
            <div class="col-lg-12">
              <b-tabs class="vertical-tabs" pills vertical nav-wrapper-class="col-md-4 col-12" active-nav-item-class="bg-primary text-black">
                <b-tab active>
                  <template #title> Show empathy </template>
                  <p>
                    <strong>For example:</strong>
                  </p>
                  <ul class="dot">
                    <li>"I understand your concerns and worries."</li>
                    <li>"How are you feeling?"</li>
                    <li>"If you would like to talk about it, I’m here to listen."</li>
                  </ul>
                </b-tab>
                <b-tab>
                  <template #title> Be non-judgemental </template>
                  <p>Discussing past health behaviours may not be helpful. Be positive and focus on what they can do to protect their future health.</p>

                  <p>
                    <strong>For example:</strong>
                  </p>

                  <ul class="dot">
                    <li>"Many people in a situation like yours would have that reaction."</li>
                    <li>"I can understand why you feel sad."</li>
                    <li>"What are you thinking of doing, and how can I help?"</li>
                  </ul>
                </b-tab>
                <b-tab>
                  <template #title> Provide helpful, practical support </template>
                  <p>
                    <strong>For example:</strong>
                  </p>

                  <ul class="dot">
                    <li>"Would you like me to go to the doctor with you?"</li>
                    <li>"I can collect the kids from school if you want to make an appointment?"</li>
                    <li>"Did you get a reminder letter for your screening appointment? Do you want me to check the register online and see if they have your correct details?"</li>
                  </ul>

                  <div class="info-box">
                    <p>
                      Find out more about the <b-link href="https://www.screeningservice.ie/" target="_blank" title="Open in new window">National Screening Service</b-link> or
                      <strong>Freephone 1800 45 45 55</strong>.
                    </p>
                  </div>
                </b-tab>
                <b-tab>
                  <template #title> Share information on supports and services </template>
                  <p>
                    <strong>For example:</strong>
                  </p>

                  <ul class="dot">
                    <li>"I picked up this leaflet in the clinic – you might be interested?"</li>
                    <li>"I heard the Irish Cancer Society advert on the radio. They have a Support Line. It’s free to call. The number is <strong>1800 200 700</strong>."</li>
                    <li>The Irish Cancer Society website has lots of really useful information if you want to find out more.</li>
                  </ul>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Next steps -->
    <div class="container-fluid">
      <div class="row">
        <div class="container image-block-fade">
          <div class="row">
            <div class="image-block-content col-md-7 my-lg-5 align-self-center">
              <h2>Your next steps…</h2>
              <ul class="dot mb-0">
                <li>Talk to a family member or friend today about spotting cancer early.</li>
                <li>Continue to the next section for a summary and next steps.</li>
              </ul>
            </div>

            <div class="col-md-5 px-0">
              <div class="image-fade-lr bg-1"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modals for select reveals -->
    <Anxiety></Anxiety>
    <PastExperiences></PastExperiences>
    <Denial></Denial>
    <BadExperience></BadExperience>
    <TooBusy></TooBusy>
    <Embarrassment></Embarrassment>
    <FeelingHopeless></FeelingHopeless>
  </div>
</template>

<script>
import $ from "jquery";
import Anxiety from "../../components/modals/Anxiety";
import PastExperiences from "../../components/modals/PastExperiences";
import Denial from "../../components/modals/Denial";
import BadExperience from "../../components/modals/BadExperience";
import TooBusy from "../../components/modals/TooBusy";
import Embarrassment from "../../components/modals/Embarrassment";
import FeelingHopeless from "../../components/modals/FeelingHopeless";

export default {
  name: "HelpingFamilyFriends",

  components: {
    Anxiety,
    PastExperiences,
    Denial,
    BadExperience,
    TooBusy,
    Embarrassment,
    FeelingHopeless,
  },

  /*
  data: function() {
    return {
      modalsViewed: [false, false, false, false, false, false, false],
    };
  },
*/

  mounted: function() {
    // Page scroll adjustment
    $("a.scroll-link").click(function() {
      var sectionTo = $(this).attr("href");
      $("html, body").animate(
        {
          scrollTop: $(sectionTo).offset().top - 80,
        },
        1500
      );
    });
  },
};
</script>
