<template>
  <div>
    <!-- Skip to main content -->
    <b-link href="#skip-nav" class="skip">Skip to main content</b-link>

    <!-- Nav side menu overlay -->
    <b-navbar toggleable type="default">
      <b-container>
        <router-link class="navbar-brand" to="/">
          <img class="img-fluid" src="../../images/logos/client-logo.svg" title="Go back to the homepage" alt="Irish Cancer Society logo" />
        </router-link>

        <div class="ml-auto">
          <b-button variant="link navigation-help" class="mr-md-4 mr-3" v-b-modal.HowToNavigateModal>How to navigate</b-button>

          <b-navbar-toggle v-b-toggle.navside-menu title="Open menu">
            <span class="text">Menu</span>
            <span class="hamburger">
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
            </span>
          </b-navbar-toggle>
        </div>
      </b-container>
    </b-navbar>
    <!-- End nav side menu overlay -->

    <!-- Side bar menu items -->
    <b-sidebar id="navside-menu" no-header bg-variant="none" text-variant="none" backdrop no-close-on-backdrop right shadow>
      <template #default="{ hide }">
        <div class="module-box">
          <div class="module-menu-close">
            <b-button variant="close-button" title="Close menu" @click="hide"></b-button>
          </div>
          <div class="row">
            <div class="col-6 align-self-center">
              <h2 class="module-title">Spotting cancer early</h2>
            </div>
            <div class="col-6 align-self-center">
              <img class="img-fluid rounded-circle" src="../../images/general/daffodil-small-graphic.svg" alt="Illustrated graphic of the Irish Cancer Society daffodil" />
            </div>
          </div>
          <span class="module-completed">{{ percentageComplete }}% complete</span>
        </div>
        <!-- Menu page links -->

        <nav>
          <b-nav vertical>
            <li class="nav-item" v-bind:class="{ complete: isPageViewed('/') }"><router-link to="/">Welcome</router-link></li>
            <li class="nav-item" v-bind:class="{ complete: isPageViewed('/WhySpotCancerEarly') }"><router-link to="/WhySpotCancerEarly">Why spot cancer early?</router-link></li>
            <li class="nav-item" v-bind:class="{ complete: isPageViewed('/HowToSpotCancerEarly') }">
              <router-link to="/HowToSpotCancerEarly">How to spot cancer early</router-link>
            </li>
            <li class="nav-item" v-bind:class="{ complete: isPageViewed('/GoingToTheDoctor') }">
              <router-link to="/GoingToTheDoctor">Going to the doctor</router-link>
            </li>
            <li class="nav-item" v-bind:class="{ complete: isPageViewed('/ScreeningForCancer') }">
              <router-link to="/ScreeningForCancer">Screening for cancer</router-link>
            </li>
            <li class="nav-item" v-bind:class="{ complete: isPageViewed('/HelpingFamilyFriends') }">
              <router-link to="/HelpingFamilyFriends">Helping family and friends to spot cancer early</router-link>
            </li>
            <li class="nav-item" v-bind:class="{ complete: isPageViewed('/SummaryNextSteps') }">
              <router-link to="/SummaryNextSteps">Summary and next steps</router-link>
            </li>
          </b-nav>
        </nav>
      </template>
    </b-sidebar>

    <!-- Sticky Div -->
    <StickyDiv></StickyDiv>

    <!-- Modal -->
    <HowToNavigate></HowToNavigate>
  </div>
</template>

<script>
import $ from "jquery";
import { mapGetters } from "vuex";

import StickyDiv from "./StickyDiv";
import HowToNavigate from "../modals/HowToNavigate";

export default {
  name: "NavSideMenuOverlay",
  components: {
    StickyDiv,
    HowToNavigate,
  },

  computed: mapGetters(["isPageViewed", "isAssessmentPassed", "percentageComplete", "currentIndex", "totalPages"]),

  mounted: function() {
    // If the sidebar menu is open add the overflow-hidden class to the body to remove duplicate scroll bars
    $(".navbar .navbar-toggler").click(function() {
      $("body").addClass("overflow-hidden");
    });

    // If the sidebar menu is closed remove the overflow-hidden class from the body to enable page scroll again
    $("#navside-menu .btn-close-button, #navside-menu a").click(function() {
      $("body").removeClass("overflow-hidden");
    });
  },
};
</script>
