<template>
  <div id="HowToSpotCancerEarlyPage">
    <!-- Splash curved -->
    <div class="splash-curved">
      <div class="container-fluid">
        <div class="row">
          <div class="container splash-content">
            <div class="row">
              <div class="col-lg-6 order-md-1 col-md-8 order-2 align-self-center">
                <h1 class="main-heading border-top-3 border-bottom-3 py-4">How to spot cancer early</h1>

                <div class="scroll-control mt-lg-5 mt-4">
                  <a class="scroll-link" href="#scroll-point" title="Scroll to the main content"
                    >Scroll down to begin<span class="arrow-down" role="img" aria-hidden="true"></span
                  ></a>
                </div>
              </div>
              <div class="col-lg-6 order-md-2 col-md-4 order-1 col-4 px-0 my-xl-n5 align-self-center">
                <img class="img-fluid" src="../../images/howtospotcancerearly/daffodil-howtospotcancerearly.png" alt="Photo of a woman checking her breasts for signs of cancer" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Text wide -->
    <div id="skip-nav" class="container-fluid bg-white">
      <div id="scroll-point" class="row">
        <div class="container content-block">
          <div class="row">
            <div class="col-lg-12">
              <h2 class="h-underline">Introduction</h2>
              <p>Spotting cancer early means:</p>

              <ul class="dot">
                <li>Knowing your body and what’s normal for you, so you notice if something changes.</li>

                <li>Knowing the changes to look out for.</li>
              </ul>

              <p>This section will help you identify some of these changes.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Text and video -->
    <div class="container-fluid bg-faded-blue">
      <div class="row">
        <div class="container content-block">
          <div class="row">
            <div class="col-lg-6 align-self-center">
              <h2>Signs and symptoms</h2>
              <p>
                There are some signs and symptoms to be particularly aware of. They don’t necessarily mean you have cancer, but you should go to your doctor as soon as possible to
                check.
              </p>

              <p>The main changes to look out for are <strong>UNEXPLAINED</strong>, <strong>PERSISTENT</strong>, or <strong>UNUSUAL</strong>.</p>

              <p>Watch the video to find out more about these changes.</p>

              <p>
                You can also view our
                <b-link href="documents/SpotCancerEarly2020.pdf" target="_blank" title="Open in new window"> Spot Cancer Early poster</b-link> and our
                <b-link href="documents/115430_spot_cancer_early_dl_final_2017.pdf" target="_blank" title="Open in new window"> Spot Cancer Early leaflet</b-link>.
              </p>
            </div>
            <div class="col-lg-6 align-self-center">
              <VideoArea :video="video1"></VideoArea>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-12">
              <div class="info-box my-4">
                <p>
                  If you notice these or <strong>any other change</strong> in how your body works, <strong>talk to your doctor</strong>. The chances are it will not be cancer. But
                  getting it checked is not wasting anyone’s time. <strong>It could save your life</strong>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Text and image -->
    <div class="container-fluid bg-white">
      <div class="row">
        <div class="container content-block">
          <div class="row">
            <div class="col-md-8">
              <h2>Spot the signs and symptoms early</h2>
              <p>The cancers listed below are common cancers:</p>

              <ul class="dot">
                <li>Skin cancer (non-melanoma and melanoma).</li>
                <li>Bowel (colorectal) cancer.</li>
                <li>Prostate cancer.</li>
                <li>Breast cancer.</li>
                <li>Lung cancer.</li>
              </ul>

              <p class="text-icon"><span class="icon-type icon-pointer bg-secondary text-white"></span><strong>Select each leaflet to find out the signs and symptoms</strong>.</p>
            </div>

            <div class="col-md-4 mb-md-4 d-md-block d-none text-center">
              <img class="img-fluid rounded-circle" src="../../images/howtospotcancerearly/spot-the-signs.jpg" alt="Photo of an eldery man talking to his GP" />
            </div>
          </div>

          <!-- Downloadable content -->
          <div class="row mt-4">
            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1500">
              <div class="card mb-3">
                <div class="row no-gutters">
                  <div class="col-4 pl-xl-0 pl-lg-3 text-center align-self-center">
                    <img
                      class="img-fluid"
                      src="../../images/howtospotcancerearly/non-melanoma-skin-leaflet.jpg"
                      alt="Front cover of the Be Skin Smart - Non-Melanoma Skin Cancer poster"
                    />
                  </div>
                  <div class="col-8 align-self-center">
                    <div class="card-body">
                      <h3 class="h6 card-title">Non-melanoma skin cancer</h3>
                      <a href="documents/Skin_Cancer_Awareness_Poster_2021_D.pdf" class="btn btn-outline-purple px-4" target="_blank" title="Open in new window">View</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1500">
              <div class="card mb-3">
                <div class="row no-gutters">
                  <div class="col-4 pl-xl-0 pl-lg-3 text-center align-self-center">
                    <img
                      class="img-fluid"
                      src="../../images/howtospotcancerearly/skin-cancer-melanoma-leaflet.jpg"
                      alt="Front cover of the Be Skin Smart - Signs of Melanoma poster"
                    />
                  </div>
                  <div class="col-8 align-self-center">
                    <div class="card-body">
                      <h3 class="h6 card-title">Melanoma skin cancer</h3>
                      <a href="documents/Skin_Cancer_Awareness_Poster_2021_C.pdf" class="btn btn-outline-purple px-4" target="_blank" title="Open in new window">View</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1500">
              <div class="card mb-3">
                <div class="row no-gutters">
                  <div class="col-4 pl-xl-0 pl-lg-3 text-center align-self-center">
                    <img class="img-fluid" src="../../images/howtospotcancerearly/bowel-cancer-leaflet.jpg" alt="Front cover of the Bowel cancer - Know what to look for poster" />
                  </div>
                  <div class="col-8 align-self-center">
                    <div class="card-body">
                      <h3 class="h6 card-title">Bowel cancer</h3>
                      <a href="documents/Bowel_cancer_infographic_A4.pdf" class="btn btn-outline-purple px-4" target="_blank" title="Open in new window">View</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1500">
              <div class="card mb-3">
                <div class="row no-gutters">
                  <div class="col-4 pl-xl-0 pl-lg-3 text-center align-self-center">
                    <img class="img-fluid" src="../../images/howtospotcancerearly/prostate-cancer-leaflet.jpg" alt="Front cover of the Symptoms of prostate cancer poster" />
                  </div>
                  <div class="col-8 align-self-center">
                    <div class="card-body">
                      <h3 class="h6 card-title">Prostate cancer</h3>
                      <a href="documents/Prostate_cancer_poster.pdf" class="btn btn-outline-purple px-4" target="_blank" title="Open in new window">View</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1500">
              <div class="card mb-3">
                <div class="row no-gutters">
                  <div class="col-4 pl-xl-0 pl-lg-3 text-center align-self-center">
                    <img
                      class="img-fluid"
                      src="../../images/howtospotcancerearly/breast-cancer-leaflet.jpg"
                      alt="Front cover of the Breast cancer - Know what to look for poster"
                    />
                  </div>
                  <div class="col-8 align-self-center">
                    <div class="card-body">
                      <h3 class="h6 card-title">Breast cancer</h3>
                      <a href="documents/Breast_cancer_infographic_A4.pdf" class="btn btn-outline-purple px-4" target="_blank" title="Open in new window">View</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1500">
              <div class="card mb-3">
                <div class="row no-gutters">
                  <div class="col-4 pl-xl-0 pl-lg-3 text-center align-self-center">
                    <img class="img-fluid" src="../../images/howtospotcancerearly/lung-cancer-leaflet.jpg" alt="Front cover of the Lung cancer - what you should know poster" />
                  </div>
                  <div class="col-8 align-self-center">
                    <div class="card-body">
                      <h3 class="h6 card-title">Lung cancer</h3>
                      <a href="documents/Lung_cancer_symptoms_A4.pdf" class="btn btn-outline-purple px-4" target="_blank" title="Open in new window">View</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-lg-12" data-aos="fade-up" data-aos-duration="1500">
              <div class="info-box">
                <p>
                  If you would like to know more about these cancers and any other type of cancer, visit
                  <b-link href="https://www.cancer.ie/cancer-information-and-support/cancer-types" target="_blank" title="Open in new window">Cancer types</b-link>
                  or <strong>talk to a cancer nurse, Monday to Friday 9am - 5pm, on our Support Line on Freephone 1800 200 700</strong>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Next steps -->
    <div class="container-fluid">
      <div class="row">
        <div class="container image-block-fade">
          <div class="row">
            <div class="image-block-content col-md-7 my-lg-5 align-self-center">
              <h2>Your next steps…</h2>
              <ul class="dot mb-0">
                <li>Get to know your body and check for any unusual, persistent or unexplained changes.</li>
                <li>Continue to the next section so you can learn about going to the doctor.</li>
              </ul>
            </div>

            <div class="col-md-5 px-0">
              <div class="image-fade-lr bg-1"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import VideoArea from "../../components/VideoArea";

export default {
  name: "WhySpotCancerEarly",

  components: {
    VideoArea,
  },

  data: function() {
    return {
      video1: {
        id: 1,
        source: {
          plain: "./media/howtospotcancerearly/know-the-signs-of-cancer.mp4",
        },
        //captions: "./media/howtospotcancerearly/know-the-signs-of-cancer-captions.vtt",
        poster: "./media/howtospotcancerearly/know-the-signs-of-cancer-thumbnail.jpg",
      },
    };
  },

  mounted: function() {
    // Page scroll adjustment
    $("a.scroll-link").click(function() {
      var sectionTo = $(this).attr("href");
      $("html, body").animate(
        {
          scrollTop: $(sectionTo).offset().top - 80,
        },
        1500
      );
    });
  },
};
</script>
