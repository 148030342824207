//import api from '../../scorm/SCORM_API_wrapper';
import saw from "scorm-api-wrapper";

const state = {
  startTimeStamp: Date,
  reachedEnd: false,
  assessmentPassed: false,
  processedUnload: false,
};

// Putting some options here to handle different completion criteria
const getters = {
  isCompleted: (state) => state.assessmentPassed,
  isReachedEnd: (state) => state.reachedEnd,
  isAssessmentPassed: (state) => state.assessmentPassed,
};

const actions = {
  async doStart({ commit }) {
    commit("setStartTimeStamp", new Date());

    if (process.env.VUE_APP_USE_SCORM === "true") {
      saw.initialize();

      console.log("saw has been initialised");

      const completionStatus = saw.getScormValue("cmi.core.lesson_status");
      if (completionStatus == "not attempted") {
        saw.setScormValue("cmi.core.lesson_status", "incomplete");
      } else if (completionStatus == "completed") {
        commit("setAssessmentPassed", true);
      }
    }
  },
  async doAssessmentPassed({ commit }) {
    commit("setAssessmentPassed", true);

    if (process.env.VUE_APP_USE_SCORM === "true") {
      saw.setScormValue("cmi.core.lesson_status", "completed");
    }
  },
  async doCompleted({ commit }) {
    commit("setReachedEnd", true);

    if (process.env.VUE_APP_USE_SCORM === "true") {
      saw.setScormValue("cmi.core.lesson_status", "completed");
    }
  },
  async doUnload({ commit }) {
    if (state.processedUnload) {
      return;
    }

    commit("setProcessedUnload", true);

    if (process.env.VUE_APP_USE_SCORM === "true") {
      var endTimeStamp = new Date();
      var totalMilliseconds = endTimeStamp.getTime() - state.startTimeStamp.getTime();
      var scormTime = ConvertMilliSecondsToSCORMTime(totalMilliseconds, false);

      saw.setScormValue("cmi.core.session_time", scormTime);

      if (!state.reachedEnd) {
        saw.setScormValue("cmi.core.exit", "suspend");
      }

      saw.commit();

      saw.finish();
    }
  },
};

const mutations = {
  setAssessmentPassed: (state, assessmentPassed) => {
    state.assessmentPassed = assessmentPassed;
  },
  setReachedEnd: (state, reachedEnd) => {
    state.reachedEnd = reachedEnd;
  },
  setStartTimeStamp: (state, timestamp) => {
    state.startTimeStamp = timestamp;
  },
  setProcessedUnload: (state, processedUnload) => {
    state.processedUnload = processedUnload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

//SCORM requires time to be formatted in a specific way
function ConvertMilliSecondsToSCORMTime(intTotalMilliseconds, blnIncludeFraction) {
  var intHours;
  var intMinutes;
  var intSeconds;
  var intMilliseconds;
  var intHundredths;
  var strCMITimeSpan;

  if (blnIncludeFraction == null || blnIncludeFraction == undefined) {
    blnIncludeFraction = true;
  }

  //extract time parts
  intMilliseconds = intTotalMilliseconds % 1000;

  intSeconds = ((intTotalMilliseconds - intMilliseconds) / 1000) % 60;

  intMinutes = ((intTotalMilliseconds - intMilliseconds - intSeconds * 1000) / 60000) % 60;

  intHours = (intTotalMilliseconds - intMilliseconds - intSeconds * 1000 - intMinutes * 60000) / 3600000;

  /*
    deal with exceptional case when content used a huge amount of time and interpreted CMITimstamp 
    to allow a number of intMinutes and seconds greater than 60 i.e. 9999:99:99.99 instead of 9999:60:60:99
    note - this case is permissable under SCORM, but will be exceptionally rare
    */

  if (intHours == 10000) {
    intHours = 9999;

    intMinutes = (intTotalMilliseconds - intHours * 3600000) / 60000;
    if (intMinutes == 100) {
      intMinutes = 99;
    }
    intMinutes = Math.floor(intMinutes);

    intSeconds = (intTotalMilliseconds - intHours * 3600000 - intMinutes * 60000) / 1000;
    if (intSeconds == 100) {
      intSeconds = 99;
    }
    intSeconds = Math.floor(intSeconds);

    intMilliseconds = intTotalMilliseconds - intHours * 3600000 - intMinutes * 60000 - intSeconds * 1000;
  }

  //drop the extra precision from the milliseconds
  intHundredths = Math.floor(intMilliseconds / 10);

  //put in padding 0's and concatinate to get the proper format
  strCMITimeSpan = ZeroPad(intHours, 4) + ":" + ZeroPad(intMinutes, 2) + ":" + ZeroPad(intSeconds, 2);

  if (blnIncludeFraction) {
    strCMITimeSpan += "." + intHundredths;
  }

  //check for case where total milliseconds is greater than max supported by strCMITimeSpan
  if (intHours > 9999) {
    strCMITimeSpan = "9999:99:99";

    if (blnIncludeFraction) {
      strCMITimeSpan += ".99";
    }
  }

  return strCMITimeSpan;
}

function ZeroPad(intNum, intNumDigits) {
  var strTemp;
  var intLen;
  var i;

  strTemp = new String(intNum);
  intLen = strTemp.length;

  if (intLen > intNumDigits) {
    strTemp = strTemp.substr(0, intNumDigits);
  } else {
    for (i = intLen; i < intNumDigits; i++) {
      strTemp = "0" + strTemp;
    }
  }

  return strTemp;
}
