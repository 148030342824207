<template>
  <div id="WhySpotCancerEarlyPage">
    <!-- Splash curved -->
    <div class="splash-curved">
      <div class="container-fluid">
        <div class="row">
          <div class="container splash-content">
            <div class="row">
              <div class="col-lg-6 order-md-1 col-md-8 order-2 align-self-center">
                <h1 class="main-heading border-top-3 border-bottom-3 py-4">Why spot cancer early?</h1>

                <div class="scroll-control mt-lg-5 mt-4">
                  <a class="scroll-link" href="#scroll-point" title="Scroll to the main content"
                    >Scroll down to begin<span class="arrow-down" role="img" aria-hidden="true"></span
                  ></a>
                </div>
              </div>
              <div class="col-lg-6 order-md-2 col-md-4 order-1 col-4 px-0 my-xl-n5 align-self-center">
                <img class="img-fluid" src="../../images/whyspotcancerearly/daffodil-whyspotcancerearly.png" alt="Photo of an elderly couple walking in a park" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Text wide -->
    <div id="skip-nav" class="container-fluid bg-white">
      <div id="scroll-point" class="row">
        <div class="container content-block">
          <div class="row">
            <div class="col-lg-12">
              <h2 class="h-underline">Introduction</h2>
              <p>You are more likely to survive cancer if you spot it at an early stage. This section explains why.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- text and video -->
    <div class="container-fluid bg-faded-blue">
      <div class="row">
        <div class="container content-block">
          <div class="row">
            <div class="col-lg-5 align-self-center">
              <h2>Mary went to her doctor early</h2>
              <p>Play the video to find out how much of a difference this made.</p>
            </div>
            <div class="col-lg-7 align-self-center">
              <VideoArea :video="video1"></VideoArea>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Flip card interaction -->
    <div class="container-fluid bg-white">
      <div class="row">
        <div class="container content-block">
          <div class="row">
            <div class="col-lg-5 align-self-center">
              <h2 class="h-underline">Look at the difference</h2>
              <p>The earlier cancer is diagnosed, the greater the chance that you will <strong>survive</strong> or <strong>live for a longer time</strong>.</p>

              <p>Look at some example statistics to see the difference in survival after 5 years by spotting cancer early and spotting it at a later stage.</p>
            </div>

            <div class="col-lg-7 mt-lg-0 mt-4">
              <div class="chart-wrapper">
                <h3 class="chart-title text-center">Five year survival by stage after diagnosis</h3>
                <apexchart type="bar" height="380" :options="chartOptions" :series="series"></apexchart>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Next steps -->
    <div class="container-fluid">
      <div class="row">
        <div class="container image-block-fade">
          <div class="row">
            <div class="image-block-content col-md-7 my-lg-5 align-self-center">
              <h2>Your next steps…</h2>
              <ul class="dot mb-0">
                <li>Continue to the next section to learn how to spot cancer early.</li>
              </ul>
            </div>
            <div class="col-md-5 px-0">
              <div class="image-fade-lr bg-1"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import VideoArea from "../../components/VideoArea";

export default {
  name: "WhySpotCancerEarly",

  components: {
    VideoArea,
  },

  data: function() {
    return {
      video1: {
        id: 1,
        source: {
          plain: "./media/whyspotcancerearly/marywalsh-patient.mp4",
        },
        captions: "./media/whyspotcancerearly/marywalsh-patient-captions.vtt",
        poster: "./media/whyspotcancerearly/marywalsh-patient-thumbnail.jpg",
      },

      // Apex data entries
      series: [
        {
          name: "Late Stage 4",
          data: [12, 4, 26, 45],
        },
        {
          name: "Early Stage 1",
          data: [95, 51, 97, 98],
        },
      ],

      // Apex chart options
      chartOptions: {
        chart: {
          type: "bar",
          height: 380,
          stacked: true,
          toolbar: {
            show: false,
          },
        },

        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              hideOverflowingLabels: false,
            },
          },
        },

        xaxis: {
          categories: ["Bowel (colorectal)", "Lung", "Breast", "Prostate"],

          labels: {
            show: true,
            rotate: 0,
            rotateAlways: false,
            hideOverlappingLabels: false,
            trim: false,
            offsetY: 4,
            style: {
              fontFamily: "Montserrat, Arial, sans-serif",
              fontSize: "14px",
              fontWeight: 400,
            },
          },
        },

        yaxis: {
          show: true,
          labels: {
            show: false,
          },
          title: {
            text: "%",
            rotate: -180,
            offsetX: 5,
            style: {
              fontFamily: "Montserrat, Arial, sans-serif",
              fontSize: "16px",
              fontWeight: 400,
            },
          },

          axisBorder: {
            show: true,
            color: "#e0e0e0",
            offsetX: -1,
            offsetY: 0,
          },
        },

        legend: {
          position: "top",
          offsetX: 0,
          offsetY: 0,
          fontFamily: "Montserrat, Arial, sans-serif",
          fontSize: "14px",
          fontWeight: 400,
        },

        colors: ["#000099", "#ffd600"], // Legend box colours

        fill: {
          opacity: 1,
          colors: ["#000099", "#ffd600"], // Bar char colours
        },

        // Bar char data labels
        dataLabels: {
          enabled: true,
          style: {
            fontFamily: "Montserrat, Arial, sans-serif",
            fontSize: "16px",
            fontWeight: "bold",
            colors: ["#000"], // Data label colours
          },

          formatter: function(val) {
            return val + "%";
          },

          background: {
            enabled: true,
            foreColor: "#fff",
            padding: 5,
            borderRadius: 5,
            borderWidth: 0,
            opacity: 1,
            dropShadow: {
              enabled: false,
              top: 1,
              left: 1,
              blur: 1,
              color: "#000",
              opacity: 1,
            },
          },
        },

        // Remove hover affect
        states: {
          hover: {
            filter: {
              type: "none",
            },
          },
        },

        // Remove tool tip on hover
        tooltip: {
          enabled: false,
        },

        // Responsive settings for bar chart
        responsive: [
          {
            breakpoint: 768,
            options: {
              xaxis: {
                labels: {
                  trim: true,
                  style: {
                    fontSize: "12px",
                  },
                },
              },
            },
          },
        ],
      },
    };
  },

  mounted: function() {
    // Page scroll adjustment
    $("a.scroll-link").click(function() {
      var sectionTo = $(this).attr("href");
      $("html, body").animate(
        {
          scrollTop: $(sectionTo).offset().top - 80,
        },
        1500
      );
    });
  },
};
</script>
