<template>
  <b-modal id="screening3Modal" size="lg" centered modal-class="content-modal" no-stacking no-close-on-backdrop hide-footer @shown="modalShown">
    <template #modal-header="{ close }">
      <div class="modal-close-button">
        <b-button variant="close-button" title="Close modal pop up" @click="close()"></b-button>
      </div>
      <h3 class="modal-title h-underline" id="screening3ModalLabel">I know I need to, but I keep forgetting to book the appointment</h3>
    </template>

    <b-row>
      <b-col md="12">
        <p>Play the video to find out the advice from a nurse.</p>
        <VideoArea :video="video1"></VideoArea>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import VideoArea from "../../components/VideoArea";

export default {
  name: "Screening3",

  components: {
    VideoArea,
  },

  methods: {
    modalShown() {
      this.$emit("opened", 6);
    },
  },

  data: function() {
    return {
      video1: {
        id: 1,
        source: {
          plain: "./media/screeningforcancer/screening3/screening3.mp4",
        },
        captions: "./media/screeningforcancer/screening3/screening3-captions.vtt",
        poster: "./media/screeningforcancer/screening3/screening3-thumbnail.jpg",
      },
    };
  },
};
</script>
