import { VideoChoiceEnum } from "../../enums";

const state = {
  descriptionActive: false,
  islActive: false,
};

const getters = {
  currentVideoChoice: (state) => {
    if (state.descriptionActive) {
      return VideoChoiceEnum.AUDIO_DESCRIPTION;
    } else if (state.islActive) {
      return VideoChoiceEnum.IRISH_SIGN_LANGUAGE;
    } else {
      return VideoChoiceEnum.PLAIN;
    }
  },
  isAudioDescriptionActive: (state) => {
    return state.descriptionActive;
  },
  isIrishSignLanguageActive: (state) => {
    return state.islActive;
  },
};

const actions = {
  async loadValuesFromLocalStorage({ commit }) {
    console.log("Desc: " + localStorage.getItem("descriptionActive"));
    console.log("ISL: " + localStorage.getItem("islActive"));
    if (localStorage.getItem("descriptionActive") === "true") {
      commit("setDescriptionActive", true);
    } else if (localStorage.getItem("islActive") === "true") {
      commit("setIslActive", true);
    }
  },
  async flipAudioDescription({ commit }) {
    commit("setDescriptionActive", !state.descriptionActive);

    if (state.descriptionActive) {
      commit("setIslActive", false);
    }
  },
  async flipIrishSignLanguage({ commit }) {
    commit("setIslActive", !state.islActive);

    if (state.islActive) {
      commit("setDescriptionActive", false);
    }
  },
};

const mutations = {
  setDescriptionActive: (state, active) => {
    state.descriptionActive = active;
    localStorage.setItem("descriptionActive", active);
  },
  setIslActive: (state, active) => {
    state.islActive = active;
    localStorage.setItem("islActive", active);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
