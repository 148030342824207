<template>
  <b-modal id="makeSureYouUnderstandModal" size="lg" centered modal-class="content-modal" no-stacking no-close-on-backdrop hide-footer @shown="modalShown">
    <template #modal-header="{ close }">
      <div class="modal-close-button">
        <b-button variant="close-button" title="Close modal pop up" @click="close()"></b-button>
      </div>
      <h3 class="modal-title h-underline mb-4" id="makeSureYouUnderstandModalLabel">Make sure you understand</h3>
    </template>

    <div class="conversation-scroll">
      <b-row class="mb-md-4">
        <b-col md="4" class="align-self-center text-center p-0">
          <img class="img-fluid" src="../../images/goingtothedoctor/make-sure-you-understand.png" alt="Photo of an elderly woman, with her hand on her face, looking confused" />
        </b-col>
        <b-col md="8" class="align-self-center">
          <div class="speech-bubble left-up v-middle-down py-md-4">
            <div class="speech-bubble-content">
              <span class="quotes"></span>
              <p><strong>I didn’t understand the part about… Please explain it to me again</strong>.</p>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-row>
      <b-col md="12">
        <p>
          Sometimes we leave a doctor’s appointment wishing we’d asked more questions or got more information. Take your time and try not feel rushed. Make sure you’re clear about
          everything before you leave.
        </p>

        <ul class="dot">
          <li>Ask the doctor to explain again if you don’t understand everything.</li>
          <li>Take time to ask questions.</li>
          <li>Take someone with you, to ask questions and help you to remember everything afterwards.</li>
          <li>Make sure you know how to contact the doctor if you have any further questions.</li>
          <li>Arrange another appointment if you have unanswered questions or things don’t improve. Go back to the same doctor or get advice from another medical professional.</li>
        </ul>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
export default {
  name: "MakeSureYouUnderstand",
  methods: {
    modalShown() {
      this.$emit("opened", 4);
    },
  },
};
</script>
