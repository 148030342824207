<template>
  <div v-if="show">
    <div class="container-fluid breadcrumb-bar">
      <div class="container">
        <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [],
      item: {},
      breadcrumb: [],
    };
  },
  computed: {
    show() {
      return this.items.length > 0 && this.item.name != "Home";
    },
  },

  name: "BreadCrumb",

  watch: {
    $route() {
      this.getRoute();
    },
  },

  methods: {
    getRoute() {
      this.items = this.$route.matched;
      this.item = this.items[0];

      this.breadcrumb = [
        {
          text: this.item.meta.parentname,
          to: { name: this.item.meta.parentpath },
        },
        {
          text: this.item.name,
          active: true,
        },
      ];

      console.log(this.$route);
    },
  },

  create() {
    this.getRoute();
  },
};
</script>
