<template>
  <b-modal id="beClearModal" size="lg" centered modal-class="content-modal" no-stacking no-close-on-backdrop hide-footer @shown="modalShown">
    <template #modal-header="{ close }">
      <div class="modal-close-button">
        <b-button variant="close-button" title="Close modal pop up" @click="close()"></b-button>
      </div>
      <h3 class="modal-title h-underline mb-4" id="beClearModalLabel">Be clear about symptoms</h3>
    </template>

    <div class="conversation-scroll">
      <b-row class="mb-md-4">
        <b-col md="4" class="align-self-center text-center p-0">
          <img class="img-fluid" src="../../images/goingtothedoctor/be-clear.png" alt="Photo of a middle aged man looking hopeful" />
        </b-col>
        <b-col md="8" class="align-self-center">
          <div class="speech-bubble left-up v-middle-down py-md-4">
            <div class="speech-bubble-content">
              <span class="quotes"></span>
              <p><strong>I’ve had a cough, and it’s lasted 3 weeks</strong>.</p>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-row>
      <b-col md="12">
        <p>The more information you can give about your symptoms the better, so write down:</p>

        <ul class="dot">
          <li>What symptoms you’re worried about.</li>
          <li>How long you have had them.</li>
          <li>How they feel.</li>
          <li>Have they got worse since you first noticed them?</li>
          <li>Are the symptoms there all the time or do they come and go?</li>
          <li>Does anything make your symptoms worse or better? For example, exercising, eating certain foods.</li>
          <li>Have you noticed any other change in your body, even if it’s somewhere else from your main problem?</li>
        </ul>

        <div class="info-box">
          <p><strong>Don’t be afraid to go back if the symptoms persist</strong>.</p>
        </div>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
export default {
  name: "BeClear",
  methods: {
    modalShown() {
      this.$emit("opened", 5);
    },
  },
};
</script>
