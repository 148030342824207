<template>
  <div>
    <div class="video-block">
      <vue-plyr ref="plyr" :options="videoOptions">
        <video controls crossorigin playsinline :data-poster="posterSrc">
          <source :src="videoSrc" type="video/mp4" />
          <track default kind="captions" label="English captions" :src="captionsSrc" srclang="en" />
        </video>
      </vue-plyr>
    </div>
    <!-- Video options for ISL and Audio description if needed -->
    <!--
    <b-button type="button" variant="primary" class="video-control audio-description" @click="buttonClicked('description')"
      ><span>{{ audioDescriptionButtonStatus }} Audio Description</span></b-button
    >
    <b-button type="button" variant="primary" class="video-control sign-language" @click="buttonClicked('isl')"
      ><span>{{ islButtonStatus }} ISL</span></b-button
    >
    -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { VideoChoiceEnum } from "../enums";

export default {
  name: "VideoArea",
  props: ["video"],
  data() {
    // Add captions button if captions are available
    var withCaptions = [
      "play", // Play/pause playback
      "play-large", // Default large play button
      "progress", // The progress bar and scrubber for playback and buffering
      "current-time", // The current time of playback
      "mute", // Mute toggle
      "volume", // Volume control
      "captions", // Toggle captions
      "fullscreen", // Play video in fullscreen mode
    ];

    // Remove caption button if captions aren't available
    var withoutCaptions = [
      "play", // Play/pause playback
      "play-large", // Default large play button
      "progress", // The progress bar and scrubber for playback and buffering
      "current-time", // The current time of playback
      "mute", // Mute toggle
      "volume", // Volume control
      "fullscreen", // Play video in fullscreen mode
    ];

    return {
      descriptionToggle: false,
      islToggle: false,
      videoOptions: {
        hideControls: false,
        disableContextMenu: true,
        invertTime: false,
        fullscreen: {
          enabled: true,
          fallback: false,
          iosNative: false,
          container: null,
        },
        captions: { active: true, language: "auto", update: false },
        keyboard: { focused: true, global: false },
        tooltips: { controls: false },
        controls: this.video.captions && this.video.captions.length > 0 ? withCaptions : withoutCaptions,
      },
    };
  },
  computed: {
    ...mapGetters(["currentVideoChoice", "isAudioDescriptionActive", "isIrishSignLanguageActive"]),
    videoSrc() {
      switch (this.currentVideoChoice) {
        case VideoChoiceEnum.AUDIO_DESCRIPTION:
          return this.video.source.description;
        case VideoChoiceEnum.IRISH_SIGN_LANGUAGE:
          return this.video.source.isl;
        case VideoChoiceEnum.PLAIN:
        default:
          return this.video.source.plain;
      }
    },
    captionsSrc() {
      return this.video.captions;
    },
    posterSrc() {
      return this.video.poster;
    },
    audioDescriptionButtonStatus() {
      return this.isAudioDescriptionActive ? "Disable" : "Enable";
    },
    islButtonStatus() {
      return this.isIrishSignLanguageActive ? "Disable" : "Enable";
    },
  },
  methods: {
    ...mapActions(["flipAudioDescription", "flipIrishSignLanguage"]),
    buttonClicked: function(button) {
      this.$refs.plyr.player.stop();
      if (button === "description") {
        this.flipAudioDescription();
      } else if (button === "isl") {
        this.flipIrishSignLanguage();
      }

      this.updateSources();

      this.$emit("sourcechanged");
    },

    updateSources() {
      this.$refs.plyr.player.source = {
        type: "video",
        sources: [
          {
            src: this.videoSrc,
            type: "video/mp4",
          },
        ],
        poster: this.posterSrc,
      };
    },
    playButtonClicked() {
      this.$refs.plyr.player.play();
    },

    stop() {
      this.$refs.plyr.player.stop();
    },
  },
  mounted() {
    this.$refs.plyr.player.on("playing", () => {
      this.$emit("playing", this.video.id);
    });
  },
};
</script>
