<template>
  <b-modal id="speakOutModal" size="lg" centered modal-class="content-modal" no-stacking no-close-on-backdrop hide-footer @shown="modalShown">
    <template #modal-header="{ close }">
      <div class="modal-close-button">
        <b-button variant="close-button" title="Close modal pop up" @click="close()"></b-button>
      </div>
      <h3 class="modal-title h-underline mb-4" id="speakOutModalLabel">Speak out</h3>
    </template>

    <div class="conversation-scroll">
      <b-row class="mb-md-4">
        <b-col md="4" class="align-self-center text-center p-0">
          <img class="img-fluid" src="../../images/goingtothedoctor/speak-out.png" alt="Photo of a middle aged man speaking with his hands" />
        </b-col>
        <b-col md="8" class="align-self-center">
          <div class="speech-bubble left-up v-middle-down py-md-4">
            <div class="speech-bubble-content">
              <span class="quotes"></span>
              <p><strong>This is unusual for me. I don’t normally have…</strong></p>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-row>
      <b-col md="12">
        <ul class="dot">
          <li>Mention anything that’s on your mind, even if the doctor doesn’t ask.</li>
          <li>Tell the doctor if you’re worried about anything.</li>
          <li>Be honest and try not to be embarrassed – your doctor has seen and heard it all before.</li>
          <li>Give as much information as you can if the doctor asks you anything.</li>
        </ul>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
export default {
  name: "speakOut",
  methods: {
    modalShown() {
      this.$emit("opened", 2);
    },
  },
};
</script>
