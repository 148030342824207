import saw from "scorm-api-wrapper";

// What is the 'Next' page?
// What is the 'Previous' page?
// Have I visited a particular page?
// Have I visited all pages?
// Saving progress to SCORM API?
// Retrieving progress form SCORM API?

const state = {
  pages: [],
  currentPage: "/",
  loadedSavedState: false,
  savedProgressFound: false,
  savedLastLocation: "",
  savedSuspendData: "",
  askedToResume: false,
};

const getters = {
  viewedAllPages: (state) => {
    return state.pages.filter((page) => !page.visited).length === 0;
  },
  isPageViewed: (state) => (path) => {
    return state.pages.find((page) => page.path === path).visited;
  },
  nextPage: (state) => {
    let currentIndex = state.pages.findIndex((page) => page.path === state.currentPage);
    if (currentIndex === state.pages.length - 1) return null;
    else {
      let nextIndex = currentIndex + 1;
      return state.pages[nextIndex].path;
    }
  },
  previousPage: (state) => {
    let currentIndex = state.pages.findIndex((page) => page.path === state.currentPage);
    if (currentIndex === 0) return null;
    else {
      let previousIndex = currentIndex - 1;
      return state.pages[previousIndex].path;
    }
  },
  currentPage: (state) => {
    return state.currentPage;
  },
  savedLastLocation: (state) => state.savedLastLocation,
  wasSavedProgressFound: (state) => state.savedProgressFound,
  isSavedProgressLoaded: (state) => state.loadedSavedState,
  haveAskedToResume: (state) => state.askedToResume,

  percentageComplete: (state) => {
    let vistedPagesCount = state.pages.filter((page) => page.visited === true).length;
    if (vistedPagesCount === 0) return 0;
    else {
      return ((vistedPagesCount / state.pages.length) * 100).toFixed(0);
    }
  },

  currentIndex: (state) => state.pages.findIndex((page) => page.path === state.currentPage),
  totalPages: (state) => state.pages.length - 1, //not including loading page
};

const actions = {
  async initialiseNavigation({ commit }, routerPages) {
    var pagesObj = new Array();

    Array.from(routerPages).map((routerPage) => {
      pagesObj.push({ path: routerPage.path, visited: false });
    });

    commit("setPages", pagesObj);

    if (process.env.VUE_APP_USE_SCORM === "true") {
      // Check if progress was saved
      const jsonString = saw.getScormValue("cmi.suspend_data");
      commit("setSavedSuspendData", jsonString);
      console.log("Suspend data below:");
      console.log(jsonString);

      let lastLocation = saw.getScormValue("cmi.core.lesson_location");
      commit("setSavedLastLocation", lastLocation);
      console.log("Last saved location was " + lastLocation);

      if (jsonString != null && jsonString != "" && lastLocation != null && lastLocation != "") {
        commit("setSavedProgressFound", true);
        console.log("Setting Saved Progress to TRUE");
      }
    }

    commit("setSavedProgressLoaded", true);
  },
  async resumeSavedProgress({ commit }) {
    console.log("Request to resume progress");
    if (state.savedSuspendData != null && state.savedSuspendData != "") {
      const jsonObj = JSON.parse(decodeURI(state.savedSuspendData));
      jsonObj
        .filter((page) => page.visited)
        .forEach((page) => {
          const viewedPage = { path: page.path, visited: true };
          commit("setPageViewed", viewedPage);
        });
    }

    if (state.savedLastLocation != null && state.savedLastLocation != "") {
      commit("setCurrentPage", state.savedLastLocation);
      const viewedPage = { path: state.savedLastLocation, visited: true };
      commit("setPageViewed", viewedPage);
    }
  },
  async goToPage({ commit }, destinationPage) {
    commit("setCurrentPage", destinationPage);

    //dispatch('scorm/doLocationChange', destinationPage, { root:true });
    if (process.env.VUE_APP_USE_SCORM === "true") {
      console.log("Setting last location in LMS to " + destinationPage);
      saw.setScormValue("cmi.core.lesson_location", destinationPage);
    }

    const viewedPage = { path: destinationPage, visited: true };
    commit("setPageViewed", viewedPage);

    if (process.env.VUE_APP_USE_SCORM === "true") {
      console.log("Saving pages state to LMS");
      saw.setScormValue("cmi.suspend_data", encodeURI(JSON.stringify(state.pages)));

      saw.commit();
    }
  },
  async doAskedToResume({ commit }) {
    commit("setAskedToResume", true);
  },
};

const mutations = {
  setPages: (state, pages) => {
    state.pages = pages;
  },
  setCurrentPage: (state, destinationPage) => {
    state.currentPage = destinationPage;
  },
  setPageViewed: (state, pageViewed) => {
    const existsAtIndex = state.pages.findIndex((p) => p.path === pageViewed.path);
    if (existsAtIndex !== -1) {
      state.pages[existsAtIndex] = pageViewed;
    }
    state.pages = [...state.pages];
  },
  setSavedProgressFound: (state, progressFound) => {
    state.savedProgressFound = progressFound;
  },
  setSavedLastLocation: (state, savedLastLocation) => {
    state.savedLastLocation = savedLastLocation;
  },
  setSavedSuspendData: (state, savedSuspendData) => {
    state.savedSuspendData = savedSuspendData;
  },
  setSavedProgressLoaded: (state, savedProgressLoaded) => {
    state.loadedSavedState = savedProgressLoaded;
  },
  setAskedToResume: (state, askedToResume) => {
    state.askedToResume = askedToResume;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
