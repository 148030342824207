<template>
  <div id="GoingToTheDoctorPage">
    <!-- Splash curved -->
    <div class="splash-curved">
      <div class="container-fluid">
        <div class="row">
          <div class="container splash-content">
            <div class="row">
              <div class="col-lg-6 order-md-1 col-md-8 order-2 align-self-center">
                <h1 class="main-heading border-top-3 border-bottom-3 py-4">Going to the doctor</h1>

                <div class="scroll-control mt-lg-5 mt-4">
                  <a class="scroll-link" href="#scroll-point" title="Scroll to the main content"
                    >Scroll down to begin<span class="arrow-down" role="img" aria-hidden="true"></span
                  ></a>
                </div>
              </div>
              <div class="col-lg-6 order-md-2 col-md-4 order-1 col-4 px-0 my-xl-n5 align-self-center">
                <img class="img-fluid" src="../../images/goingtothedoctor/daffodil-goingtothedoctor.png" alt="Photo of a elderly patient talking with their Doctor" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Text wide -->
    <div id="skip-nav" class="container-fluid bg-white">
      <div id="scroll-point" class="row">
        <div class="container content-block">
          <div class="row">
            <div class="col-lg-12">
              <h2 class="h-underline">Introduction</h2>
              <p>
                Being more aware of signs and symptoms of cancer is a great way to look after your health, but if you do notice any changes in your body, you may not know what to
                do next or be scared to find out more.
              </p>

              <p><strong>The first step is to go to your doctor</strong>.</p>

              <p>If your doctor thinks your symptoms need to be looked at, you will have more tests at a hospital or a special Rapid Access Clinic.</p>

              <p>Most times the changes will not be cancer, but it’s always best to get checked.</p>

              <p>In this section, you’ll find out:</p>

              <ul class="dot">
                <li>Why it’s important to go to your doctor early.</li>
                <li>What happens at a doctor’s appointment.</li>
                <li>How to get the most from a doctor’s appointment.</li>
                <li>How to overcome any barriers you or a loved one might have in going to the doctor.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Video -->
    <div class="container-fluid bg-faded-blue">
      <div class="row">
        <div class="container content-block">
          <div class="row">
            <div class="col-lg-5 align-self-center">
              <h2>Why it’s important to go to your doctor early</h2>
              <p>Play the video to find out why it’s important to go to your doctor early if you notice any changes in your body, and what happens at the doctor’s appointment.</p>
            </div>
            <div class="col-lg-7 align-self-center">
              <VideoArea :video="video1"></VideoArea>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Text and select reveals -->
    <div class="container-fluid bg-white">
      <div class="row">
        <div class="container content-block">
          <div class="row">
            <div class="col-lg-12">
              <h2>Getting the most from your doctor’s appointment</h2>
              <p>Here are some tips for when you go to the doctor.</p>

              <p class="text-icon"><span class="icon-type icon-pointer bg-secondary text-white"></span><strong>Select each tip to learn more</strong>.</p>
            </div>
          </div>

          <!-- Select reveals featuring an image -->
          <div class="row mt-4">
            <div class="col-md-4 mb-4" data-aos="fade-up" data-aos-duration="2000">
              <b-button v-b-modal.bePreparedModal variant="primary select-box" class="h-100 px-2" title="View be prepared" v-bind:class="{ viewed: modalsViewed[0] }">
                <img class="img-fluid" src="../../images/goingtothedoctor/be-prepared.png" alt="Photo of a middle aged woman looking anxious" />
                Be prepared
              </b-button>
            </div>
            <div class="col-md-4 mb-4" data-aos="fade-up" data-aos-duration="2000">
              <b-button v-b-modal.speakOutModal variant="primary select-box" class="h-100 px-2" title="View speak out" v-bind:class="{ viewed: modalsViewed[1] }">
                <img class="img-fluid" src="../../images/goingtothedoctor/speak-out.png" alt="Photo of a middle aged man speaking with his hands" />
                Speak out
              </b-button>
            </div>
            <div class="col-md-4 mb-4" data-aos="fade-up" data-aos-duration="2000">
              <b-button v-b-modal.askQuestionsModal variant="primary select-box" class="h-100 px-2" title="View ask questions" v-bind:class="{ viewed: modalsViewed[2] }">
                <img class="img-fluid" src="../../images/goingtothedoctor/ask-questions.png" alt="Photo of a middle aged woman looking optimistic" />
                Ask questions
              </b-button>
            </div>
            <div class="offset-md-2 col-md-4 mb-4" data-aos="fade-up" data-aos-duration="3000">
              <b-button
                v-b-modal.makeSureYouUnderstandModal
                variant="primary select-box"
                class="h-100 px-2"
                title="View make sure you understand"
                v-bind:class="{ viewed: modalsViewed[3] }"
              >
                <img
                  class="img-fluid"
                  src="../../images/goingtothedoctor/make-sure-you-understand.png"
                  alt="Photo of an elderly woman, with her hand on her face, looking confused"
                />
                Make sure you understand
              </b-button>
            </div>
            <div class="col-md-4 mb-4" data-aos="fade-up" data-aos-duration="3000">
              <b-button v-b-modal.beClearModal variant="primary select-box" class="h-100 px-2" title="View be clear about symptoms" v-bind:class="{ viewed: modalsViewed[4] }">
                <img class="img-fluid" src="../../images/goingtothedoctor/be-clear.png" alt="Photo of a middle aged man looking hopeful" />
                Be clear about symptoms
              </b-button>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-12" data-aos="fade-up" data-aos-duration="1500">
              <div class="info-box my-4">
                <p>A doctor’s visit should be a discussion between two experts. When it comes to your body and what is normal for you, <strong>you are the expert</strong>.</p>
              </div>

              <p>
                Find out more about
                <b-link
                  href="https://www.cancer.ie/cancer-information-and-support/cancer-support/coping-with-cancer/information-for-patients/treatment-tips/doctors-appointments"
                  target="_blank"
                  title="Open in new window"
                >
                  Getting the most from your doctor’s appointment</b-link
                >
                and
                <b-link
                  href="https://www.cancer.ie/cancer-information-and-support/cancer-information/health-literacy-clearer-cancer-communication-for-better-health"
                  target="_blank"
                  title="Open in new window"
                  >Health literacy</b-link
                >.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Text and image -->
    <div class="container-fluid">
      <div class="row">
        <div class="container content-block">
          <div class="row">
            <div class="col-md-8">
              <h2>Why we may not go to the doctor</h2>
              <p>Here are some reasons why people may not go to their doctor. Do any of these resonate with you or a loved one?</p>

              <p class="text-icon">
                <span class="icon-type icon-pointer bg-secondary text-white"></span><strong>Select each reason/statement to read the advice from a doctor</strong>.
              </p>

              <!-- Accordion -->
              <div class="standard-accordion my-4" role="tablist">
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" role="tab">
                    <b-button block v-b-toggle.accordion-1 variant="link" data-toggle="collapse">I’m afraid of being diagnosed with cancer – I’d rather not know</b-button>
                  </b-card-header>
                  <b-collapse id="accordion-1" visible accordion="standard-accordion" role="tabpanel">
                    <b-card-body>
                      <b-card-text>
                        It is OK to be fearful. Chances are it will not be cancer, but recognising and acting on changes early gives a much better outcome.
                      </b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" role="tab">
                    <b-button block v-b-toggle.accordion-2 variant="link" data-toggle="collapse">I’ve had cancer before, I won’t get it again</b-button>
                  </b-card-header>
                  <b-collapse id="accordion-2" accordion="standard-accordion" role="tabpanel">
                    <b-card-body>
                      <b-card-text>
                        It is possible to develop a second cancer since we are living longer nowadays. Always be aware of any unusual changes and get them checked out.
                      </b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" role="tab">
                    <b-button block v-b-toggle.accordion-3 variant="link" data-toggle="collapse">I'm due a screening test in a few months, I'll wait until then</b-button>
                  </b-card-header>
                  <b-collapse id="accordion-3" accordion="standard-accordion" role="tabpanel">
                    <b-card-body>
                      <b-card-text>
                        While screening is an important part of cancer detection, if you develop any signs or symptoms in between the screening, go to your doctor.
                      </b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" role="tab">
                    <b-button block v-b-toggle.accordion-4 variant="link" data-toggle="collapse">I’m too young to have cancer</b-button>
                  </b-card-header>
                  <b-collapse id="accordion-4" accordion="standard-accordion" role="tabpanel">
                    <b-card-body>
                      <b-card-text> Although cancer is more common in older people, it is possible to develop cancer at any age. </b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" role="tab">
                    <b-button block v-b-toggle.accordion-5 variant="link" data-toggle="collapse">It's a waste of my time and money</b-button>
                  </b-card-header>
                  <b-collapse id="accordion-5" accordion="standard-accordion" role="tabpanel">
                    <b-card-body>
                      <b-card-text> It might feel like that now, but spotting cancer early could save your life. </b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" role="tab">
                    <b-button block v-b-toggle.accordion-6 variant="link" data-toggle="collapse">I can’t seem to get an appointment when I ring</b-button>
                  </b-card-header>
                  <b-collapse id="accordion-6" accordion="standard-accordion" role="tabpanel">
                    <b-card-body>
                      <b-card-text>
                        When you call the surgery, let them know that you are worried about a cancer symptom. Keep on trying until you get an appointment, and if you want to talk
                        to someone about your symptoms in the meantime call our cancer nurses on <strong>Freephone 1800 200 700</strong>.
                      </b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>

              <p>
                If you are finding it hard to get an appointment or feeling anxious waiting for results, call our Support Line nurses to talk things over on
                <strong>1800 200 700</strong> or email
                <b-link href="mailto:supportline@irishcancer.ie" target="_blank" title="Open in new window">supportline@irishcancer.ie</b-link>.
              </p>
            </div>

            <div class="offset-md-0 col-md-4 offset-3 col-6 mb-lg-0 mt-4 text-center">
              <img class="img-fluid rounded-circle" src="../../images/goingtothedoctor/gp-with-patient.jpg" alt="Photo of an elderly man speaking to his doctor" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Text wide -->
    <div class="container-fluid bg-white">
      <div class="row">
        <div class="container content-block">
          <div class="row">
            <div class="col-lg-12">
              <h2>What happens next?</h2>
              <p>Find out more about what happens at a doctor’s appointment and what tests are involved, for some common cancers.</p>

              <p class="text-icon"><span class="icon-type icon-pointer bg-secondary text-white"></span><strong>Select each leaflet to learn more</strong>.</p>
            </div>
          </div>

          <!-- Downloadable content -->
          <div class="row mt-4">
            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1500">
              <div class="card mb-3">
                <div class="row no-gutters">
                  <div class="col-4 text-center align-self-center">
                    <img
                      class="img-fluid"
                      src="../../images/goingtothedoctor/non-melanoma-skin-cancer-leaflet.jpg"
                      alt="Front cover of the Non-melanoma Skin Cancer - What You Should Know leaflet"
                    />
                  </div>
                  <div class="col-8 align-self-center">
                    <div class="card-body">
                      <h3 class="h6 card-title">Non-melanoma Skin Cancer - What You Should Know leaflet</h3>
                      <a href="documents/Non-Melanoma_What_you_should_know.pdf" class="btn btn-outline-purple px-4" target="_blank" title="Open in new window">View</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1500">
              <div class="card mb-3">
                <div class="row no-gutters">
                  <div class="col-4 text-center align-self-center">
                    <img
                      class="img-fluid"
                      src="../../images/goingtothedoctor/melanoma-skin-cancer-leaflet.jpg"
                      alt="Front cover of the Melanoma Skin Cancer - What You Should Know leaflet"
                    />
                  </div>
                  <div class="col-8 align-self-center">
                    <div class="card-body">
                      <h3 class="h6 card-title">Melanoma Skin Cancer - What You Should Know leaflet</h3>
                      <a href="documents/8449_ics_wysk_cancer_leaflet_melanoma.pdf" class="btn btn-outline-purple px-4" target="_blank" title="Open in new window">View</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1500">
              <div class="card mb-3">
                <div class="row no-gutters">
                  <div class="col-4 text-center align-self-center">
                    <img class="img-fluid" src="../../images/goingtothedoctor/bowel-cancer-leaflet.jpg" alt="Front cover of the Bowel Cancer - What You Should Know leaflet" />
                  </div>
                  <div class="col-8 align-self-center">
                    <div class="card-body">
                      <h3 class="h6 card-title">Bowel Cancer - What You Should Know leaflet</h3>
                      <a href="documents/Bowel_WYSK_2020.pdf" class="btn btn-outline-purple px-4" target="_blank" title="Open in new window">View</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="2500">
              <div class="card mb-3">
                <div class="row no-gutters">
                  <div class="col-4 text-center align-self-center">
                    <img
                      class="img-fluid"
                      src="../../images/goingtothedoctor/prostate-cancer-leaflet.jpg"
                      alt="Front cover of the Prostate Cancer - What You Should Know leaflet"
                    />
                  </div>
                  <div class="col-8 align-self-center">
                    <div class="card-body">
                      <h3 class="h6 card-title">Prostate Cancer - What You Should Know leaflet</h3>
                      <a href="documents/8449_ics_wysk_cancer_leaflet_prostate.pdf" class="btn btn-outline-purple px-4" target="_blank" title="Open in new window">View</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="2500">
              <div class="card mb-3">
                <div class="row no-gutters">
                  <div class="col-4 text-center align-self-center">
                    <img class="img-fluid" src="../../images/goingtothedoctor/breast-cancer-leaflet.jpg" alt="Front cover of the Breast Cancer - What You Should Know leaflet" />
                  </div>
                  <div class="col-8 align-self-center">
                    <div class="card-body">
                      <h3 class="h6 card-title">Breast Cancer - What You Should Know leaflet</h3>
                      <a href="documents/10615_Irish_Cancer_Society_Breast_cancer_leaflet.pdf" class="btn btn-outline-purple px-4" target="_blank" title="Open in new window"
                        >View</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="2500">
              <div class="card mb-3">
                <div class="row no-gutters">
                  <div class="col-4 text-center align-self-center">
                    <img class="img-fluid" src="../../images/goingtothedoctor/lung-cancer-leaflet.jpg" alt="Front cover of the Lung Cancer - What You Should Know leaflet" />
                  </div>
                  <div class="col-8 align-self-center">
                    <div class="card-body">
                      <h3 class="h6 card-title">Lung Cancer - What You Should Know leaflet</h3>
                      <a href="documents/Lung_WYSK_2021.pdf" class="btn btn-outline-purple px-4" target="_blank" title="Open in new window">View</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="2500">
              <div class="card mb-3">
                <div class="row no-gutters">
                  <div class="col-4 text-center align-self-center">
                    <img
                      class="img-fluid"
                      src="../../images/goingtothedoctor/mouth-head-neck-cancer-leaflet.jpg"
                      alt="Front cover of the Mouth Head and Neck Cancers - What you should know leaflet"
                    />
                  </div>
                  <div class="col-8 align-self-center">
                    <div class="card-body">
                      <h3 class="h6 card-title">Mouth Head and Neck Cancers - What you should knowleaflet</h3>
                      <a href="documents/wysk_mouth_head_and_neck_web.pdf" class="btn btn-outline-purple px-4" target="_blank" title="Open in new window">View</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="2500">
              <div class="card mb-3">
                <div class="row no-gutters">
                  <div class="col-4 text-center align-self-center">
                    <img
                      class="img-fluid"
                      src="../../images/goingtothedoctor/cervical-cancer-leaflet.jpg"
                      alt="Front cover of the Cervical Cancer – What you should know leaflet"
                    />
                  </div>
                  <div class="col-8 align-self-center">
                    <div class="card-body">
                      <h3 class="h6 card-title">Cervical Cancer – What you should know leaflet</h3>
                      <a href="documents/WYSK_cervical_cancer_2021_web.pdf" class="btn btn-outline-purple px-4" target="_blank" title="Open in new window">View</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="2500">
              <div class="card mb-3">
                <div class="row no-gutters">
                  <div class="col-4 text-center align-self-center">
                    <img class="img-fluid" src="../../images/goingtothedoctor/testicular-cancer-leaflet.jpg" alt="Front cover of the Testicular Cancer – What you should know" />
                  </div>
                  <div class="col-8 align-self-center">
                    <div class="card-body">
                      <h3 class="h6 card-title">Testicular Cancer – What you should know leaflet</h3>
                      <a href="documents/wysk_testicular_web.pdf" class="btn btn-outline-purple px-4" target="_blank" title="Open in new window">View</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Next steps -->
    <div class="container-fluid">
      <div class="row">
        <div class="container image-block-fade">
          <div class="row">
            <div class="image-block-content col-md-7 align-self-center">
              <h2>Your next steps…</h2>

              <ul class="dot mb-0">
                <li>Know what’s normal for you.</li>
                <li>Check for persistent, unexplained and unusual changes</li>
                <li>Make an appointment with your doctor if you are worried about any changes in your body. Tell them if you’re worried about cancer.</li>
                <li>Continue to the next section so you can learn about screening.</li>
              </ul>
            </div>

            <div class="col-md-5 px-0">
              <div class="image-fade-lr bg-1"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modals for select reveals -->
    <BePrepared @opened="modalOpened"></BePrepared>
    <SpeakOut @opened="modalOpened"></SpeakOut>
    <AskQuestions @opened="modalOpened"></AskQuestions>
    <MakeSureYouUnderstand @opened="modalOpened"></MakeSureYouUnderstand>
    <BeClear @opened="modalOpened"></BeClear>
  </div>
</template>

<script>
import $ from "jquery";
import VideoArea from "../../components/VideoArea";
import BePrepared from "../../components/modals/BePrepared";
import SpeakOut from "../../components/modals/SpeakOut";
import AskQuestions from "../../components/modals/AskQuestions";
import MakeSureYouUnderstand from "../../components/modals/MakeSureYouUnderstand";
import BeClear from "../../components/modals/BeClear";

export default {
  name: "GoingToTheDoctor",

  components: {
    VideoArea,
    BePrepared,
    SpeakOut,
    AskQuestions,
    MakeSureYouUnderstand,
    BeClear,
  },

  data: function() {
    return {
      modalsViewed: [false, false, false, false, false],

      video1: {
        id: 1,
        source: {
          plain: "./media/goingtothedoctor/go-to-your-doctor-early.mp4",
        },
        captions: "./media/goingtothedoctor/go-to-your-doctor-early-captions.vtt",
        poster: "./media/goingtothedoctor/go-to-your-doctor-early-thumbnail.jpg",
      },
    };
  },

  methods: {
    modalOpened(modalId) {
      this.$set(this.modalsViewed, modalId - 1, true);
    },
  },

  mounted: function() {
    // Page scroll adjustment
    $("a.scroll-link").click(function() {
      var sectionTo = $(this).attr("href");
      $("html, body").animate(
        {
          scrollTop: $(sectionTo).offset().top - 80,
        },
        1500
      );
    });
  },
};
</script>
