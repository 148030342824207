<template>
  <b-modal id="bePreparedModal" size="lg" centered modal-class="content-modal" no-stacking no-close-on-backdrop hide-footer @shown="modalShown">
    <template #modal-header="{ close }">
      <div class="modal-close-button">
        <b-button variant="close-button" title="Close modal pop up" @click="close()"></b-button>
      </div>
      <h3 class="modal-title h-underline mb-4" id="bePreparedModalLabel">Be prepared</h3>
    </template>

    <div class="conversation-scroll">
      <b-row class="mb-md-4">
        <b-col md="4" class="align-self-center text-center p-0">
          <img class="img-fluid" src="../../images/goingtothedoctor/be-prepared.png" alt="Photo of a middle aged woman looking anxious" />
        </b-col>
        <b-col md="8" class="align-self-center">
          <div class="speech-bubble left-up v-middle-down py-md-4">
            <div class="speech-bubble-content">
              <span class="quotes"></span>
              <p><strong>Doctor, you should know that my dad had prostate cancer, and my aunt (my dad’s sister) had breast cancer</strong>.</p>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-row>
      <b-col md="12">
        <p>Your doctor will probably ask a lot of questions, so think about:</p>

        <ul class="dot">
          <li>Any medical problems, when you had them and any treatments you had.</li>
          <li>If there’s any history of cancer or other illnesses in the family.</li>
          <li>Your lifestyle.</li>
          <li>Your general physical and mental health.</li>
          <li>Any medication you’re taking.</li>
        </ul>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
export default {
  name: "BePrepared",
  methods: {
    modalShown() {
      this.$emit("opened", 1);
    },
  },
};
</script>
