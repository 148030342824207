import Vuex from 'vuex';
import Vue from 'vue';
import scorm from './modules/scorm';
import navigation from './modules/navigation';
import video from './modules/video';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        scorm,
        navigation,
        video
    }
});