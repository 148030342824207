<template>
  <b-modal id="badExperienceModal" size="lg" centered modal-class="quote-modal" no-stacking no-close-on-backdrop hide-footer>
    <template #modal-header="{ close }">
      <div class="modal-close-button">
        <b-button variant="close-button" title="Close modal pop up" @click="close()"></b-button>
      </div>
    </template>

    <b-row no-gutters>
      <b-col lg="4" class="align-self-end text-center order-lg-1 order-2 col-8 offset-lg-0 offset-2">
        <img class="img-fluid" src="../../images/helpingfamilyfriends/bad-experience.png" alt="Photo of a man looking unhappy" />
      </b-col>

      <b-col lg="8" class="align-self-center order-lg-2 order-1">
        <h3 class="modal-title mb-4" id="badExperienceModalLabel">Bad experience of the health system</h3>

        <p class="quote-text">Sure, the treatments are worse than the illness.</p>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
export default {
  name: "BadExperience",
};
</script>
