// --- IMPORTS --- --- --- ---
// --- --- --- --- --- --- ---

import Vue from "vue";
import VueRouter from "vue-router";
import store from "./store";
import App from "./App.vue";

// Sections/topics
import Home from "./pages/HomePage/Home";
import WhySpotCancerEarly from "./pages/WhySpotCancerEarlyPage/WhySpotCancerEarly";
import HowToSpotCancerEarly from "./pages/HowToSpotCancerEarlyPage/HowToSpotCancerEarly";
import GoingToTheDoctor from "./pages/GoingToTheDoctorPage/GoingToTheDoctor";
import ScreeningForCancer from "./pages/ScreeningForCancerPage/ScreeningForCancer";
import HelpingFamilyFriends from "./pages/HelpingFamilyFriendsPage/HelpingFamilyFriends";
import SummaryNextSteps from "./pages/SummaryNextStepsPage/SummaryNextSteps";

// --- LIBRARY/FRAMEWORK IMPORTS --- --- ---
// --- --- --- --- --- --- --- --- --- --- -

// Import Bootstrap vue
import { BootstrapVue } from "bootstrap-vue";

// Import vue plyr
import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";

// Import main stylesheet - includes imports of Bootstrap and Bootstrap vue scss files for re-compiling
import "./scss/style.scss";

// Import AOS - animation effect library
import AOS from "aos";
import "aos/dist/aos.css";

// Import apex charts
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);

Vue.use(BootstrapVue);

Vue.use(VuePlyr, {
  plyr: {},
});

Vue.use(VueRouter);

// --- PAGE ROUTER LINKS --- --- ---
// --- --- --- --- --- --- --- --- -

export const router = new VueRouter({
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
      meta: {
        title: "Spotting cancer early | Welcome",
        parentname: "Home",
        parentpath: "Home",
      },
    },

    {
      path: "/WhySpotCancerEarly",
      name: "Why spot cancer early?",
      component: WhySpotCancerEarly,
      meta: {
        title: "Spotting cancer early | Why spot cancer early?",
        parentname: "Home",
        parentpath: "Home",
      },
    },

    {
      path: "/HowToSpotCancerEarly",
      name: "How to spot cancer early",
      component: HowToSpotCancerEarly,
      meta: {
        title: "Spotting cancer early | How to spot cancer early",
        parentname: "Home",
        parentpath: "Home",
      },
    },

    {
      path: "/GoingToTheDoctor",
      name: "Going to the doctor",
      component: GoingToTheDoctor,
      meta: {
        title: "Spotting cancer early | Going to the doctor",
        parentname: "Home",
        parentpath: "Home",
      },
    },

    {
      path: "/ScreeningForCancer",
      name: "Screening for cancer",
      component: ScreeningForCancer,
      meta: {
        title: "Spotting cancer early | Screening for cancer",
        parentname: "Home",
        parentpath: "Home",
      },
    },

    {
      path: "/HelpingFamilyFriends",
      name: "Helping family and friends to spot cancer early",
      component: HelpingFamilyFriends,
      meta: {
        title: "Spotting cancer early | Helping family and friends to spot cancer early",
        parentname: "Home",
        parentpath: "Home",
      },
    },

    {
      path: "/SummaryNextSteps",
      name: "Summary and next steps",
      component: SummaryNextSteps,
      meta: {
        title: "Spotting cancer early | Summary and next steps",
        parentname: "Home",
        parentpath: "Home",
      },
    },
  ],

  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

// --- VUE SETUP --- --- ---
// --- --- --- --- --- --- -

new Vue({
  router,
  store,
  render: (h) => h(App),
  mounted() {
    AOS.init({
      // Global settings:
      once: true, // whether animation should happen only once - while scrolling down
    });
  },
}).$mount("#app");
