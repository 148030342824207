<template>
  <!-- Back to the top of the page button -->
  <button id="back-to-top" title="Back to the top of the page" tabindex="0" class="btn" type="button" aria-label="Scroll to top of page">
    <span class="sr-only">Scroll back to the top of the page</span>
  </button>
</template>

<script>
import $ from "jquery";

export default {
  name: "BackToTop",
  mounted: function() {
    // Page scroll adjustment
    $("a.scroll-link, a.skip").click(function() {
      var sectionTo = $(this).attr("href");
      $("html, body").animate(
        {
          scrollTop: $(sectionTo).offset().top - 80,
        },
        1500
      );
    });

    var btn = $("#back-to-top");
    $(window).scroll(function() {
      300 < $(window).scrollTop() ? btn.addClass("show") : btn.removeClass("show");
    }),
      btn.on("click keypress", function(e) {
        e.preventDefault(), $("html, body").animate({ scrollTop: 0 }, "300");
      });

    // Scroll to view video in tab container
    $(function() {
      var btnTab = $(".nav-link");

      btnTab.on("click", function() {
        var tabContent = $(this).data("tabContent");
        $("html,body").animate(
          {
            scrollTop: $("#" + tabContent).offset().top - 100,
          },
          1000
        );
      });
    });
  },
};
</script>
