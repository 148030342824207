<template>
  <div>
    <!-- Navside menu overlay-->
    <NavSideMenuOverlay></NavSideMenuOverlay>

    <div>
      <main><router-view></router-view></main>
    </div>
    <PageNav></PageNav>
    <BackToTop></BackToTop>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { mapGetters } from "vuex";

import NavSideMenuOverlay from "./components/layouts/NavSideMenuOverlay";
import PageNav from "./components/layouts/PageNav";
import BackToTop from "./components/BackToTop.vue";

export default {
  name: "App",
  components: {
    NavSideMenuOverlay,
    PageNav,
    BackToTop,
  },
  computed: mapGetters(["currentPage", "viewedAllPages"]),

  methods: mapActions(["doStart", "doUnload", "initialiseNavigation", "goToPage", "loadValuesFromLocalStorage", "doCompleted"]),
  created() {
    console.log("App created");
    this.doStart();
    this.loadValuesFromLocalStorage();
    this.initialiseNavigation(this.$router.options.routes);
    window.addEventListener("beforeunload", this.doUnload);
    window.addEventListener("unload", this.doUnload);
    this.$router.afterEach((to) => {
      document.title = to.meta.title || "Spotting cancer early";
      this.goToPage(to.path);

      // If all pages are viewed then set module to complete
      if (this.viewedAllPages) {
        this.doCompleted();
        console.log("Section completed");
      }
    });

    this.goToPage("/");
    console.log("Finished App created");
  },
};
</script>
