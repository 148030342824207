<template>
  <b-modal id="breastCancerModal" size="lg" centered modal-class="content-modal" no-stacking no-close-on-backdrop hide-footer @shown="modalShown">
    <template #modal-header="{ close }">
      <div class="modal-close-button">
        <b-button variant="close-button" title="Close modal pop up" @click="close()"></b-button>
      </div>
      <h3 class="modal-title h-underline" id="breastCancerModalLabel">Breast cancer screening</h3>
    </template>

    <b-row>
      <b-col lg="8">
        <p>
          Breast screening involves having a mammogram (x-ray of the breast). Women <strong>aged 50-69</strong> are invited to get a free mammogram screening
          <strong>every two years</strong>.
        </p>

        <p>But you should <strong>always</strong> be breast aware – this means looking at and feeling your <strong>breasts at least once a month</strong>.</p>

        <p>
          Visit
          <b-link href="https://www.cancer.ie/cancer-information-and-support/cancer-types/breast-cancer/how-to-check-your-breasts" target="_blank" title="Open in new window"
            >How to check your breasts</b-link
          >
          and
          <b-link href="https://www2.hse.ie/conditions/breast-screening/information/" target="_blank" title="Open in new window">Breast screening information</b-link>
          for more information.
        </p>
      </b-col>

      <b-col lg="4" class="offset-lg-0 offset-3 col-6 mb-4 text-center">
        <img class="img-fluid rounded-circle" src="../../images/screeningforcancer/breast-screening.jpg" alt="photo of a doctor demonstrating how to check for breast cancer" />
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="12">
        <div class="didyouknow-box mb-4">
          <h3 class="h4 title">Did you know?</h3>
          <p>
            <strong><span style="--font-multiplier: 1.4">Fewer than 1 in 100 women</span></strong> screened are diagnosed with cancer, and these women have a good chance of
            successful treatment.
          </p>
        </div>

        <div class="info-box mb-4">
          <p>
            You should be sent a letter when your screening is due. If you don’t, <strong>Freephone 1800 45 45 55</strong> to check you’re on the register or update your details at
            <b-link href="https://apps.breastcheck.ie/breastcheck/registration.626.brcregistration.html" target="_blank" title="Open in new window">BreastCheck</b-link>.
          </p>
        </div>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
export default {
  name: "BreastCancer",
  methods: {
    modalShown() {
      this.$emit("opened", 1);
    },
  },
};
</script>
