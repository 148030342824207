<template>
  <div id="SummaryNextStepsPage">
    <!-- Splash curved -->
    <div class="splash-curved">
      <div class="container-fluid">
        <div class="row">
          <div class="container splash-content">
            <div class="row">
              <div class="col-lg-6 order-md-1 col-md-8 order-2 align-self-center">
                <h1 class="main-heading border-top-3 border-bottom-3 py-4">Summary and next steps</h1>

                <div class="scroll-control mt-lg-5 mt-4">
                  <a class="scroll-link" href="#scroll-point" title="Scroll to the main content"
                    >Scroll down to begin<span class="arrow-down" role="img" aria-hidden="true"></span
                  ></a>
                </div>
              </div>
              <div class="col-lg-6 order-md-2 col-md-4 order-1 col-4 px-0 my-xl-n5 align-self-center">
                <img class="img-fluid" src="../../images/summarynextsteps/daffodil-summarynextsteps.png" alt="Photo of a GP talking with his patient" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Text and image -->
    <div id="skip-nav" class="container-fluid bg-white">
      <div id="scroll-point" class="row">
        <div class="container content-block">
          <div class="row">
            <div class="offset-md-0 col-md-4 offset-3 col-6 order-md-1 order-2 mb-lg-0 mt-4 text-center">
              <img class="img-fluid" src="../../images/summarynextsteps/spotcancerearly-leaflet.png" alt="Image of the spot cancer early leaflet" />
            </div>

            <div class="col-md-8 order-md-2 order-1">
              <h2 class="h-underline">Summary</h2>
              <ul class="dot">
                <li><strong>You are more likely to survive cancer or live for a longer time if you spot it at an early stage</strong>.</li>

                <li>Spotting cancer early means knowing your body and what’s normal for you, so you notice if something changes, and knowing the changes to look out for.</li>

                <li>The main changes to look out for are UNEXPLAINED, PERSISTENT, or UNUSUAL.</li>

                <li><strong>Make an appointment with your doctor if you are worried about any changes in your body. Tell them if you’re worried about cancer</strong>.</li>

                <li>
                  If your doctor thinks your symptoms need to be looked at, you will have more tests at a hospital or a special Rapid Access Clinic. Most times the changes will not
                  be cancer, but it’s always best to get checked.
                </li>

                <li>Talk to a family member or friend today and support them in seeking help.</li>

                <li>
                  Go to screening that is available to you (Breast cancer screening, Cervical cancer screening, Bowel cancer screening). Call the National Screening Service on
                  <strong>1800 45 45 55</strong> or visit their <b-link href="https://www.screeningservice.ie/" target="_blank" title="Open in new window">website</b-link> to make
                  sure you’re on the register.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Text and image -->
    <div class="container-fluid bg-faded-blue">
      <div class="row">
        <div class="container content-block">
          <div class="row">
            <div class="col-md-8">
              <h2>Further support</h2>
              <p>If you have any concerns or queries:</p>

              <p class="text-icon">
                <span class="icon-type icon-phone text-purple"></span>

                Talk to our cancer nurses, on our Support Line on <strong>Freephone 1800 200 700</strong>. It’s open Monday to Friday 9am - 5pm.
              </p>

              <p class="text-icon">
                <span class="icon-type icon-email text-purple"></span> Email
                <b-link href="mailto:supportline@irishcancer.ie" target="_blank" title="Open in new window">supportline@irishcancer.ie</b-link>
              </p>

              <p class="mt-4">You can also call into a Daffodil Centre.</p>

              <p class="mt-4">Other healthcare professionals you may wish to talk to (other than your doctor) are:</p>

              <ul class="dot mt-4">
                <li>
                  <h3 class="h5">Your dentist</h3>
                  <p>Your dentist will check for signs of cancer in your mouth and neck area during check-ups. You can tell them if you’re worried about anything.</p>
                </li>
                <li>
                  <h3 class="h5">A pharmacist</h3>
                  <p>Your local pharmacist can advise you on what to do if you have any changes in your body that you’re worried about.</p>
                </li>
              </ul>
            </div>

            <div class="offset-md-0 col-md-4 offset-3 col-6 mb-lg-0 mt-4 text-center">
              <img class="img-fluid rounded-circle" src="../../images/summarynextsteps/nurse-providing-advice.jpg" alt="Photo of nurse providing advice" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Next steps -->
    <div class="container-fluid">
      <div class="row">
        <div class="container image-block-fade">
          <div class="row">
            <div class="image-block-content col-md-7 align-self-center">
              <h2>Your next steps…</h2>
              <ul class="dot mb-0">
                <li>Share what you have learned with your family and friends.</li>
                <li>Look at the resources below to find out more.</li>
                <li>Share this resource with your family and friends (on social media).</li>
                <li>Follow the Irish Cancer Society on social media to keep up to date.</li>
              </ul>

              <ul class="social-media mt-5 mb-0 pl-0">
                <li><strong>Follow us on:</strong></li>
                <li>
                  <b-button variant="social" href="https://www.facebook.com/IrishCancerSociety/" target="_blank" title="Follow us on facebook"
                    ><i class="bi bi-facebook"></i
                  ></b-button>
                </li>
                <li>
                  <b-button variant="social" href="https://twitter.com/IrishCancerSoc" target="_blank" title="Follow us on twitter"><i class="bi bi-twitter"></i> </b-button>
                </li>

                <li>
                  <b-button variant="social" href="https://www.instagram.com/irishcancersociety/" target="_blank" title="Follow us on Instragram"
                    ><i class="bi bi-instagram"></i
                  ></b-button>
                </li>
              </ul>
            </div>

            <div class="col-md-5 px-0">
              <div class="image-fade-lr bg-1"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Resource accordion -->
    <div class="container-fluid bg-white">
      <div class="row">
        <div class="container content-block">
          <div class="row">
            <div class="col-md-12">
              <h2>Resources</h2>

              <p class="text-icon">
                <span class="icon-type icon-pointer bg-secondary text-white"></span>
                <strong>Select each area to learn more.</strong>
              </p>

              <div class="standard-accordion my-4" role="tablist">
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" role="tab">
                    <b-button block v-b-toggle.accordion-1 variant="link" data-toggle="collapse">Helpful videos</b-button>
                  </b-card-header>
                  <b-collapse id="accordion-1" visible accordion="standard-accordion" role="tabpanel">
                    <b-card-body>
                      <ul class="dot">
                        <li><b-link href="https://www.youtube.com/watch?v=eid05tRcXPA" target="_blank" title="Open in new window">Spot Cancer Early video</b-link></li>
                        <li><b-link href="https://www.youtube.com/watch?v=X42TzDkgN3o&t=2s" target="_blank" title="Open in new window">Breast awareness video</b-link></li>
                        <li><b-link href="https://www.youtube.com/watch?v=tfeb5jU3qRI" target="_blank" title="Open in new window">Bowel awareness video</b-link></li>
                        <li>
                          <b-link href="https://www.youtube.com/watch?v=bI5M2Lr6_3Q" target="_blank" title="Open in new window">Skin awareness video</b-link>
                        </li>
                        <li>
                          <b-link href="https://www.youtube.com/watch?v=XBAW7Lpd770" target="_blank" title="Open in new window">Lung awareness video</b-link>
                        </li>
                        <li>
                          <b-link href="https://www.youtube.com/watch?v=ZOxUKQ5ZznI" target="_blank" title="Open in new window">Prostate awareness video</b-link>
                        </li>
                      </ul>
                    </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" role="tab">
                    <b-button block v-b-toggle.accordion-2 variant="link" data-toggle="collapse">Helpful reading</b-button>
                  </b-card-header>
                  <b-collapse id="accordion-2" accordion="standard-accordion" role="tabpanel">
                    <b-card-body>
                      <ul class="dot">
                        <li>
                          <b-link
                            href="https://www.cancer.ie/cancer-information-and-support/cancer-information/early-detection/spot-cancer-early"
                            target="_blank"
                            title="Open in new window"
                            >Spot Cancer Early</b-link
                          >
                        </li>
                        <li>
                          <b-link href="https://www.cancer.ie/about-us/cancer-resources-publications/cancer-prevention-resources" target="_blank" title="Open in new window"
                            >Cancer prevention and awareness resources</b-link
                          >
                        </li>
                      </ul>
                    </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" role="tab">
                    <b-button block v-b-toggle.accordion-3 variant="link" data-toggle="collapse">Do an online health check</b-button>
                  </b-card-header>
                  <b-collapse id="accordion-3" accordion="standard-accordion" role="tabpanel">
                    <b-card-body>
                      <ul class="dot">
                        <li>
                          <b-link
                            href="https://www.cancer.ie/cancer-information-and-support/cancer-types/bowel-colorectal-cancer#bowel_checker"
                            target="_blank"
                            title="Open in new window"
                            >Bowel Health Checker</b-link
                          >
                        </li>
                        <li>
                          <b-link href="https://www.cancer.ie/cancer-information-and-support/cancer-types/lung-cancer" target="_blank" title="Open in new window"
                            >Lung Health Checker</b-link
                          >
                        </li>
                        <li>
                          <b-link
                            href="https://www.cancer.ie/cancer-information-and-support/cancer-types/breast-cancer#breasthealthchecker"
                            target="_blank"
                            title="Open in new window"
                            >Breast Health Checker</b-link
                          >
                        </li>
                        <li>
                          <b-link href="https://www.cancer.ie/cancer-information-and-support/cancer-types/prostate-cancer" target="_blank" title="Open in new window"
                            >Prostate Health Quiz</b-link
                          >
                        </li>
                      </ul>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Stay in touch -->
    <div class="container-fluid bg-faded-blue">
      <div class="row">
        <div class="container content-block pb-lg-0">
          <div class="row">
            <div class="col-lg-7 mb-md-5 align-self-center">
              <h2>Can we stay in touch?</h2>
              <p>
                We'd love to send you occasional email updates about our work, services and ways you can help, like volunteering and fundraising activities.
              </p>
              <p>To be included, please select the button below and complete the form.</p>

              <div class="mt-md-4">
                <b-button variant="primary" href="https://www.cancer.ie/form/sign-up-form-newsletter" target="_blank" title="Open in new window"
                  >Sign up to our newsletter</b-button
                >
              </div>
            </div>

            <div class="col-lg-5 d-lg-block d-none text-center align-self-end">
              <img class="img-fluid" src="../../images/summarynextsteps/evaluation-survey.png" alt="Photo of an elderly man and woman standing beside each other smiling" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "SummaryNextSteps",

  components: {},

  mounted: function() {
    // Page scroll adjustment
    $("a.scroll-link").click(function() {
      var sectionTo = $(this).attr("href");
      $("html, body").animate(
        {
          scrollTop: $(sectionTo).offset().top - 80,
        },
        1500
      );
    });
  },
};
</script>
