<template>
  <b-modal
    ref="HowToNavigateModal"
    id="HowToNavigateModal"
    size="md"
    centered
    modal-class="content-modal"
    no-stacking
    no-close-on-backdrop
    hide-footer
    @show="resetModal"
    @hidden="resetModal"
  >
    <template #modal-header="{ close }">
      <div class="modal-close-button">
        <b-button variant="close-button" title="Close modal pop up" @click="close()"></b-button>
      </div>
    </template>

    <!-- Tab slider -->
    <b-tabs class="tab-slider" v-model="tabSlider" pills>
      <b-tab title="How to navigate">
        <h3>How to navigate</h3>
        <p>
          The <strong>'How to navigate'</strong> instructions can be accessed at any time by selecting the link located at the top of the page. Helpful information on navigating
          the learning resource will appear.
        </p>

        <div class="text-center">
          <img class="img-fluid rounded-lg" src="../../images/home/how-to-navigate-1.jpg" alt="Image showing a close up of the how to navigate button in the learning resource" />
        </div>

        <!-- Control buttons-->
        <div class="text-center mt-4">
          <b-button variant="outline-secondary" @click="closeModal">Skip</b-button>
          <b-button @click="tabSlider++">Start</b-button>
        </div>
      </b-tab>

      <b-tab title="Menu">
        <h3>Menu</h3>
        <p>
          The <strong>Menu</strong> at the top allows you to access and revisit the sections in the learning resource. A <strong>tick</strong> will appear when you visit a new
          section or when you have reached the last page of that section. A handy <strong>percentage indicator</strong> is also shown to give you an idea of how much of the
          learning resource content you have viewed.
        </p>

        <div class="text-center">
          <img class="img-fluid rounded-lg" src="../../images/home/how-to-navigate-2.jpg" alt="Image showing an example of the learning resource's menu" />
        </div>

        <!-- Control buttons-->
        <div class="text-center mt-4">
          <b-button @click="tabSlider--">Previous</b-button>
          <b-button @click="tabSlider++">Next</b-button>
        </div>
      </b-tab>

      <b-tab title="Page controls">
        <h3>Page controls</h3>
        <p>
          To navigate through the learning resource, use the <strong>Previous</strong> and <strong>Next</strong> buttons located at the bottom of each page. These navigate you
          through the learning resource in a linear path, ensuring you don’t miss any of the content.
        </p>

        <div class="text-center">
          <img class="img-fluid rounded-lg" src="../../images/home/how-to-navigate-3.jpg" alt="Image showing a close up of the next button in the learning resource" />
        </div>

        <!-- Control buttons-->
        <div class="text-center mt-4">
          <b-button @click="tabSlider--">Previous</b-button>
          <b-button @click="tabSlider++">Next</b-button>
        </div>
      </b-tab>

      <b-tab title="Breadcrumb">
        <h3>Breadcrumb</h3>
        <p>A <strong>breadcrumb</strong> is shown below the main header of each page to indicate the section you are in.</p>

        <div class="text-center">
          <img class="img-fluid rounded-lg" src="../../images/home/how-to-navigate-4.jpg" alt="Image showing a close up of the breadcrumb trail in the learning resource" />
        </div>

        <!-- Control buttons-->
        <div class="text-center mt-4">
          <b-button @click="tabSlider--">Previous</b-button>
          <b-button @click="tabSlider++">Next</b-button>
        </div>
      </b-tab>

      <b-tab title="Media">
        <h3>Media</h3>
        <p>For <strong>video content</strong>, player controls are available to allow you to adjust certain settings.</p>

        <div class="text-center">
          <img class="img-fluid rounded-lg" src="../../images/home/how-to-navigate-5.jpg" alt="Image highlighting the media player controls" />
        </div>

        <!-- Control buttons-->
        <div class="text-center mt-4">
          <b-button @click="tabSlider--">Previous</b-button>
          <b-button variant="outline-secondary" @click="closeModal">Close</b-button>
        </div>
      </b-tab>
    </b-tabs>
  </b-modal>
</template>

<script>
export default {
  name: "HowToNavigate",

  data() {
    return {
      tabSlider: 0, // Set tab slider to the start
    };
  },

  methods: {
    closeModal() {
      this.$refs["HowToNavigateModal"].hide(); // Close modal when button is clicked
    },

    resetModal() {
      this.tabSlider = "";
    },
  },
};
</script>
