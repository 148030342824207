<template>
  <div>
    <div id="sticky-anchor"></div>
    <div id="sticky-content">
      <BreadCrumb></BreadCrumb>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import BreadCrumb from "./BreadCrumb";

export default {
  name: "StickyDiv",

  components: {
    BreadCrumb,
  },

  mounted: function() {
    // Stick items contained in the div to the top of the page on scroll
    function sticky_relocate() {
      var window_top = $(window).scrollTop();
      var div_top = $("#sticky-anchor").offset().top;
      if (window_top > div_top) {
        $("#sticky-content").addClass("stick-element");
      } else {
        $("#sticky-content").removeClass("stick-element");
      }
    }

    $(function() {
      $(window).scroll(sticky_relocate);
      sticky_relocate();
    });
  },
};
</script>
