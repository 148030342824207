<template>
  <!-- Page navigation bar -->
  <div class="container-fluid page-navigation">
    <div class="row">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-6 previous">
            <router-link v-if="previousPage" class="previous-link" :to="previousPage" title="Go to the previous page">Previous</router-link>
          </div>
          <div class="col-lg-6 col-6 next">
            <router-link v-if="nextPage" class="next-link" :to="nextPage" title="Go to the next page">Next</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PageNav",
  computed: mapGetters(["previousPage", "nextPage"]),
};
</script>
