<template>
  <div>
    <!-- Resume modal popup -->
    <b-modal v-model="resumeShow" centered no-close-on-backdrop modal-class="resume-modal">
      <template #modal-header>
        <h3 class="modal-title" id="ResumeModalTitleLabel">Resume</h3>
      </template>

      <p>Would you like to resume from where you left off?</p>

      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" class="mr-2" @click="yesClicked"> Yes </b-button>
          <b-button variant="outline-dark" @click="resumeShow = false"> No </b-button>
        </div>
      </template>
    </b-modal>

    <!-- Splash curved -->
    <div class="splash-curved bg-gradient">
      <div class="container-fluid">
        <div class="row">
          <div class="container splash-content">
            <div class="row">
              <div class="col-lg-6 order-md-1 col-md-8 order-2 align-self-center pr-xl-2">
                <h1 class="main-heading">Spotting cancer early</h1>

                <p>This resource will help <strong>you</strong> or a <strong>loved one</strong> spot cancer early and know what to do if you notice any changes in your body.</p>

                <div class="scroll-control mt-lg-5 mt-4">
                  <a class="scroll-link" href="#scroll-point" title="Scroll to the main content"
                    >Scroll down to begin<span class="arrow-down" role="img" aria-hidden="true"></span
                  ></a>
                </div>
              </div>
              <div class="col-lg-6 order-md-2 col-md-4 order-1 col-4 my-xl-n5 px-0 text-md-center align-self-center">
                <img class="img-fluid" src="../../images/general/daffodil-large-graphic.svg" alt="Illustrated graphic of the Irish Cancer Society daffodil" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Content block - text and image -->
    <div id="skip-nav" class="container-fluid">
      <div id="scroll-point" class="row">
        <div class="container content-block">
          <div class="row">
            <div class="col-lg-4 col-md-3 order-md-1 order-2 col-8 offset-md-0 offset-2 mt-md-0 mt-4 text-center">
              <img class="img-fluid rounded-circle" src="../../images/home/patient-and-gp-talking.jpg" alt="Photo of a patient talking to her GP" />
            </div>
            <div class="col-lg-8 col-md-9 order-md-2 order-md-1">
              <h2>Welcome</h2>
              <p>Not all cancers have early signs and symptoms, but for those that do, knowing what to look out for can make a <strong>big difference</strong> to your health.</p>

              <p>In this resource we’ll cover:</p>

              <ul class="dot">
                <li><b-link href="#/WhySpotCancerEarly">Why it’s important to spot cancer early</b-link>.</li>
                <li><b-link href="#/HowToSpotCancerEarly">How to spot cancer early</b-link>.</li>
                <li><b-link href="#/GoingToTheDoctor">Going to the doctor</b-link>.</li>
                <li><b-link href="#/ScreeningForCancer">The cancer screening programmes available</b-link>.</li>
                <li><b-link href="#/HelpingFamilyFriends">Helping family and friends to spot cancer early</b-link>.</li>
              </ul>

              <div class="info-box mb-4">
                <p>We use the term <strong>'you'</strong> in this resource, but the information equally applies to your <strong>loved ones</strong>.</p>
              </div>

              <p>
                If you’d like to learn about the things you can do to <strong>reduce your risk of cancer</strong>, please visit
                <b-link href="https://www.cancer.ie/cancer-information-and-support/cancer-prevention" target="_blank" title="Open in new window"
                  >Cancer prevention: Learn how a healthy lifestyle can help reduce your risk of cancer</b-link
                >.
              </p>

              <p class="text-icon">
                <span class="icon-type icon-pointer bg-secondary text-white"></span>
                <strong>Select <b-link v-b-modal.HowToNavigateModal>here</b-link> if you’d like help on how to navigate this resource.</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Home",
  components: {},

  data: function() {
    return {
      resumeShow: false,
    };
  },

  methods: {
    ...mapActions(["resumeSavedProgress", "doAskedToResume"]),
    yesClicked() {
      this.resumeShow = false;
      this.resumeSavedProgress();
      this.$router.push(this.savedLastLocation);
    },
  },

  computed: mapGetters(["isPageViewed", "isSavedProgressLoaded", "wasSavedProgressFound", "savedLastLocation", "currentPage", "haveAskedToResume"]),

  mounted: function() {
    console.log("Start of Home mounted");

    waitFor(() => this.isSavedProgressLoaded === true).then(() => {
      if (!this.haveAskedToResume) {
        if (this.wasSavedProgressFound) {
          console.log("Showing Resume Progress modal");
          this.resumeShow = true;
          this.doAskedToResume();
        }
      }
    });

    // Page scroll adjustment
    $("a.scroll-link").click(function() {
      var sectionTo = $(this).attr("href");
      $("html, body").animate(
        {
          scrollTop: $(sectionTo).offset().top - 0,
        },
        1500
      );
    });

    console.log("End of Home mounted");
  },
};

function waitFor(conditionFunction) {
  const poll = (resolve) => {
    if (conditionFunction()) resolve();
    else setTimeout(() => poll(resolve), 400);
  };

  return new Promise(poll);
}
</script>
