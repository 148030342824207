<template>
  <b-modal id="bowelCancerModal" size="lg" centered modal-class="content-modal" no-stacking no-close-on-backdrop hide-footer @shown="modalShown">
    <template #modal-header="{ close }">
      <div class="modal-close-button">
        <b-button variant="close-button" title="Close modal pop up" @click="close()"></b-button>
      </div>
      <h3 class="modal-title h-underline" id="bowelCancerModalLabel">Bowel cancer screening</h3>
    </template>

    <b-row>
      <b-col lg="8">
        <p><strong>People aged 60-69</strong> are invited to take a bowel screening test <strong>every two years</strong>.</p>

        <p>
          Bowel screening involves you collecting a sample of your poo (stool sample) at home using a
          <b-link href="https://www2.hse.ie/conditions/bowel-screening/home-screening/" target="_blank" title="Open in new window">home test</b-link> and sending it back by
          <strong>freepost</strong> for testing in a laboratory.
        </p>

        <p>
          Visit
          <b-link href="https://www2.hse.ie/conditions/bowel-screening/screening-information/" target="_blank" title="Open in new window">Bowel screening – BowelScreen</b-link>
          for more information.
        </p>
      </b-col>

      <b-col lg="4" class="offset-lg-0 offset-3 col-6 mb-4 text-center">
        <img class="img-fluid rounded-circle" src="../../images/screeningforcancer/bowel-screening.jpg" alt="Photo showing a bowel cancer screening home test kit" />
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="12">
        <div class="didyouknow-box mb-4">
          <h3 class="h4 title">Did you know?</h3>
          <p>
            The test results are expected to be <strong><span style="--font-multiplier: 1.4">normal</span></strong> for more than
            <span style="--font-multiplier: 1.4"><strong>9 in 10 people</strong></span
            >.
          </p>
        </div>

        <div class="info-box mb-4">
          <p>
            You should be sent a letter when your screening is due. If you don’t, <strong>Freephone 1800 45 45 55</strong> to check you’re on the register or update your details at
            <b-link
              href="https://apps.bowelscreen.ie/bowel-screening/verify-you-are-registered-with-bowelscreen.609.cbsregistrationpublic.html"
              target="blank"
              title="Open in new window"
              >BowelScreen</b-link
            >.
          </p>
        </div>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
export default {
  name: "BowelCancer",
  methods: {
    modalShown() {
      this.$emit("opened", 3);
    },
  },
};
</script>
