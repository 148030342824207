<template>
  <b-modal id="askQuestionsModal" size="lg" centered modal-class="content-modal" no-stacking no-close-on-backdrop hide-footer @shown="modalShown">
    <template #modal-header="{ close }">
      <div class="modal-close-button">
        <b-button variant="close-button" title="Close modal pop up" @click="close()"></b-button>
      </div>
      <h3 class="modal-title h-underline mb-4" id="askQuestionsModalLabel">Ask questions</h3>
    </template>

    <div class="conversation-scroll">
      <b-row class="mb-md-4">
        <b-col md="4" class="align-self-center text-center p-0">
          <img class="img-fluid" src="../../images/goingtothedoctor/ask-questions.png" alt="Photo of a middle aged woman looking optimistic" />
        </b-col>
        <b-col md="8" class="align-self-center">
          <div class="speech-bubble left-up v-middle-down py-md-4">
            <div class="speech-bubble-content">
              <span class="quotes"></span>
              <p><strong>I’ve prepared some questions here I’d like to ask you doctor</strong>.</p>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-row>
      <b-col md="12">
        <ul class="dot">
          <li>Write a list of what you want to ask.</li>
          <li>Listen carefully to the answers and ask the doctor to write the main things down if you’re worried you won’t remember.</li>
        </ul>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
export default {
  name: "AskQuestions",
  methods: {
    modalShown() {
      this.$emit("opened", 3);
    },
  },
};
</script>
