<template>
  <div id="ScreeningForCancerPage">
    <!-- Splash curved -->
    <div class="splash-curved">
      <div class="container-fluid">
        <div class="row">
          <div class="container splash-content">
            <div class="row">
              <div class="col-lg-6 order-md-1 col-md-8 order-2 align-self-center">
                <h1 class="main-heading border-top-3 border-bottom-3 py-4">Screening for cancer</h1>

                <div class="scroll-control mt-lg-5 mt-4">
                  <a class="scroll-link" href="#scroll-point" title="Scroll to the main content"
                    >Scroll down to begin<span class="arrow-down" role="img" aria-hidden="true"></span
                  ></a>
                </div>
              </div>
              <div class="col-lg-6 order-md-2 col-md-4 order-1 col-4 px-0 my-xl-n5 align-self-center">
                <img class="img-fluid" src="../../images/screeningforcancer/daffodil-screeningforcancer.png" alt="Photo of a middle aged woman having a mammogram" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Text wide -->
    <div id="skip-nav" class="container-fluid bg-white">
      <div id="scroll-point" class="row">
        <div class="container content-block">
          <div class="row">
            <div class="col-lg-12">
              <h2 class="h-underline">Introduction</h2>
              <p>For some cancers, screening is another way to spot cancer early.</p>

              <p>In this section you’ll find out:</p>

              <ul class="dot">
                <li>The three cancer screening programmes available in Ireland for certain age groups.</li>
                <li>How to check if your name is on the register for these programmes and get more information.</li>
                <li>How to overcome any barriers you or a loved one might have in going to screening.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Text and image -->
    <div class="container-fluid">
      <div class="row">
        <div class="container content-block">
          <div class="row">
            <div class="col-md-8">
              <h2>Screening</h2>
              <p>Testing for cancer when you have no symptoms is called screening. In Ireland there is free screening for three types of cancer:</p>

              <ul class="dot">
                <li>Breast cancer screening.</li>
                <li>Cervical cancer screening.</li>
                <li>Bowel cancer screening.</li>
              </ul>

              <p>You should automatically get a letter when you are due for screening. If you don’t, you should check if you’re on the screening register.</p>

              <div class="info-box">
                <p>If you have a strong family history of a particular cancer, tell your doctor. They may investigate in other ways or organise other tests for you.</p>
              </div>
            </div>

            <div class="offset-md-0 col-md-4 offset-3 col-6 mb-lg-0 mt-4 text-center">
              <img
                class="img-fluid"
                src="../../images/screeningforcancer/nss-cancer-logos.png"
                alt="Graphic of the National Screening Service, Cervical check, Bowel screen and Breast check logos "
              />
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-lg-12">
              <p class="text-icon">
                <span class="icon-type icon-pointer bg-secondary text-white"></span>
                <strong>Select each type of screening to learn more</strong>.
              </p>
            </div>
          </div>

          <!-- Select reveal boxes -->
          <div class="row mt-4 row-cols-1 row-cols-md-3 row-cols-lg-3">
            <div class="col mb-4" data-aos="fade-up" data-aos-duration="1500">
              <b-button v-b-modal.breastCancerModal variant="danger select-box" class="h-100" title="View breast cancer screening" v-bind:class="{ viewed: modalsViewed[0] }">
                Breast cancer screening
              </b-button>
            </div>
            <div class="col mb-4" data-aos="fade-up" data-aos-duration="2000">
              <b-button v-b-modal.cervicalCancerModal variant="success select-box" class="h-100" title="View cervical cancer screening" v-bind:class="{ viewed: modalsViewed[1] }">
                Cervical cancer screening
              </b-button>
            </div>
            <div class="col mb-4" data-aos="fade-up" data-aos-duration="2500">
              <b-button v-b-modal.bowelCancerModal variant="info select-box" class="h-100" title="View bowel cancer screening" v-bind:class="{ viewed: modalsViewed[2] }">
                Bowel cancer screening
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Text and select reveals -->
    <div class="container-fluid bg-faded-blue">
      <div class="row">
        <div class="container content-block">
          <div class="row">
            <div class="col-lg-12">
              <h2>Why we might not take part in screening</h2>
              <p>Here are some reasons why people might not take part in screening. Do any of these resonate with you or a loved one?</p>

              <p class="text-icon">
                <span class="icon-type icon-pointer bg-secondary text-white"></span><strong>Select each reason/statement to find out the advice from our cancer nurse</strong>.
              </p>
            </div>
          </div>

          <!-- Select reveal statement boxes -->
          <div class="row my-4 row-cols-1 row-cols-md-3 row-cols-lg-3 no-gutters">
            <div class="col" data-aos="fade-up" data-aos-duration="1500">
              <b-button v-b-modal.screening1Modal variant="light-teal statement-box" class="h-100" title="View content" v-bind:class="{ viewed: modalsViewed[3] }">
                I’m fit and healthy – I don’t think I’m at risk of developing cancer
              </b-button>
            </div>
            <div class="col" data-aos="fade-up" data-aos-duration="2000">
              <b-button v-b-modal.screening2Modal variant="teal statement-box" class="h-100" title="View content" v-bind:class="{ viewed: modalsViewed[4] }">
                I think it would be embarrassing or awkward to get screened
              </b-button>
            </div>
            <div class="col" data-aos="fade-up" data-aos-duration="2500">
              <b-button v-b-modal.screening3Modal variant="light-teal statement-box" class="h-100" title="View content" v-bind:class="{ viewed: modalsViewed[5] }">
                I know I need to, but I keep forgetting to book the appointment
              </b-button>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-12" data-aos="fade-up" data-aos-duration="3000">
              <div class="info-box mb-4">
                <p>
                  If you are waiting a long time to get an appointment or to get screening results, or if your contact details have changed, call the National Screening Service on
                  <strong>Freephone 1800 45 45 55</strong>.
                </p>
              </div>

              <p>
                You can develop cancer at any time. If you develop unusual symptoms, go to your doctor without delay, even if you have had recent screening, have a screening coming
                up or are outside the age range for screening.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Next steps -->
    <div class="container-fluid">
      <div class="row">
        <div class="container image-block-fade">
          <div class="row">
            <div class="image-block-content col-md-7 align-self-center">
              <h2>Your next steps…</h2>
              <ul class="dot mb-0">
                <li>If you’re invited to take part in screening, make sure you participate.</li>

                <li>
                  If you don’t get a letter, call the National Screening Service on <strong>1800 45 45 55</strong> or visit their
                  <b-link href="https://www.screeningservice.ie/" target="_blank" title="Open in new window">website</b-link> to make sure you’re on the register.
                </li>

                <li>Check that the National Screening Service have your correct contact details: Call <strong>1800 45 45 55</strong>.</li>

                <li>Continue to the next section so you can help others to spot cancer early.</li>
              </ul>
            </div>

            <div class="col-md-5 px-0">
              <div class="image-fade-lr bg-1"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modals for select reveals -->
    <BreastCancer @opened="modalOpened"></BreastCancer>
    <CervicalCancer @opened="modalOpened"></CervicalCancer>
    <BowelCancer @opened="modalOpened"></BowelCancer>

    <Screening1 @opened="modalOpened"></Screening1>
    <Screening2 @opened="modalOpened"></Screening2>
    <Screening3 @opened="modalOpened"></Screening3>
  </div>
</template>

<script>
import $ from "jquery";
import BreastCancer from "../../components/modals/BreastCancer";
import CervicalCancer from "../../components/modals/CervicalCancer";
import BowelCancer from "../../components/modals/BowelCancer";

import Screening1 from "../../components/modals/Screening1";
import Screening2 from "../../components/modals/Screening2";
import Screening3 from "../../components/modals/Screening3";

export default {
  name: "ScreeningForCancer",

  components: {
    BreastCancer,
    CervicalCancer,
    BowelCancer,

    Screening1,
    Screening2,
    Screening3,
  },

  data: function() {
    return {
      modalsViewed: [false, false, false, false, false, false],
    };
  },

  methods: {
    modalOpened(modalId) {
      this.$set(this.modalsViewed, modalId - 1, true);
    },
  },

  mounted: function() {
    // Page scroll adjustment
    $("a.scroll-link").click(function() {
      var sectionTo = $(this).attr("href");
      $("html, body").animate(
        {
          scrollTop: $(sectionTo).offset().top - 80,
        },
        1500
      );
    });
  },
};
</script>
