<template>
  <b-modal id="cervicalCancerModal" size="lg" centered modal-class="content-modal" no-stacking no-close-on-backdrop hide-footer @shown="modalShown">
    <template #modal-header="{ close }">
      <div class="modal-close-button">
        <b-button variant="close-button" title="Close modal pop up" @click="close()"></b-button>
      </div>
      <h3 class="modal-title h-underline" id="cervicalCancerModalLabel">Cervical cancer screening</h3>
    </template>

    <b-row>
      <b-col lg="8">
        <p><strong>Women aged 25-65</strong> are eligible for screening:</p>

        <ul class="dot">
          <li>Women aged 25-29 every 3 years.</li>
          <li>Women aged 30-65 every 5 years.</li>
        </ul>

        <p>Cervical screening involves your nurse or doctor using a brush like tool to take a sample from your cervix (the opening to your womb from the vagina).</p>

        <p>
          Visit
          <b-link href="https://www2.hse.ie/conditions/cervical-screening/" target="_blank" title="Open in new window">Cervical screening - CervicalCheck</b-link> for more
          information.
        </p>
      </b-col>

      <b-col lg="4" class="offset-lg-0 offset-3 col-6 mb-4 text-center">
        <img class="img-fluid rounded-circle" src="../../images/screeningforcancer/cervical-screening.jpg" alt="Illustrated graphic representing cervical cancer" />
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="12">
        <div class="didyouknow-box mb-4">
          <h3 class="h4 title">Did you know?</h3>
          <p>
            It's estimated that cervical screening can prevent <strong class="pl-1"><span style="--font-multiplier: 1.4"> 3 out of 4 cervical cancer cases</span></strong
            >.
          </p>
        </div>

        <div class="info-box mb-4">
          <p>
            You should be sent a letter when your screening is due. If you don’t, <strong>Freephone 1800 45 45 55</strong> to check you’re on the register or update your details at
            <b-link href="https://apps.cervicalcheck.ie/check-eligibility/check-eligibility.596.checkeligibilityv2.html" target="_blank" title="Open in new window"
              >CervicalCheck</b-link
            >.
          </p>
        </div>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
export default {
  name: "CervicalCancer",
  methods: {
    modalShown() {
      this.$emit("opened", 2);
    },
  },
};
</script>
